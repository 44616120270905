import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss'
import { firestore } from '../../../firebase/utils';
import * as Icon from 'react-feather';
import { collection, getDocs } from 'firebase/firestore';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const POSSales = () => {
    const dispatch = useDispatch();
    const [tab1, setTab1] = useState(false);
    const [tab2, setTab2] = useState(false);
    const [salestab, setSalesTab] = useState(false);
    const [purchasetab, setPurchases] = useState(false);
    const { currentUser, userErr } = useSelector(mapState);
    const [sales, setSales] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [preview, setPreview] = useState({});
    const [previewItems, setPreviewItems] = useState([]);
    const [previewState, setPreviewState] = useState(false);



    const today = new Date();

    // Define an array of month names in abbreviated form
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get the month, day, and year
    const day = today.getDate();
    const formattedDay = day < 10 ? `0${day}` : day;
    const month = monthNames[today.getMonth()]; // Get abbreviated month name
    const year = today.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const fullyear = today.getFullYear().toString(); // Get the last two digits of the year

    // Combine the month and year in the desired format
    const formattedYear = `${fullyear}`;
    const formattedmonthYear = `${month},${year}`;
    const formattedDate = `${formattedDay} ${month} ${year}`;



    // Fetch employee IDs and set them in the state
    const fetchTodaySalesData = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, "Users", currentUser.id, "Sales", formattedmonthYear, formattedDate));

            const emps = querySnapshot.docs.map((doc) => doc.data());

            setFilteredData(emps);
            setSales(emps);

            // console.log(emps)
        } catch (error) {
            console.error("Error getting documents: ", error);
        }
    };

    // const fetchTodayExpensesData = async () => {
    //     try {
    //         const querySnapshot = await
    //             firestore
    //                 .collection("Users")
    //                 .doc(currentUser.id)
    //                 .collection("Expenses")
    //                 .doc(formattedmonthYear)
    //                 .collection(formattedDate)
    //                 .get();

    //         const emps = querySnapshot.docs.map((doc) => doc.data());

    //         setExpenses(emps);
    //     } catch (error) {
    //         console.error("Error getting documents: ", error);
    //     }
    // };

    useEffect(() => {
        fetchTodaySalesData();
        // fetchTodayExpensesData();
    }, []);

    const totalSales = sales.reduce((sum, order) => {
        // Convert the subTotal to a number and add it to the sum
        return sum + parseFloat(order.subTotal);
    }, 0);

    // Format the totalSum as a money format
    const formattedTotalSales = totalSales.toLocaleString('en-US', {
        minimumFractionDigits: 2, // Specify the number of decimal places
    });

    const totalExpenses = expenses.reduce((sum, order) => {
        // Convert the subTotal to a number and add it to the sum
        return sum + parseFloat(order.amount);
    }, 0);

    // Format the totalSum as a money format
    const formattedTotalExpenses = totalExpenses.toLocaleString('en-US', {
        minimumFractionDigits: 2, // Specify the number of decimal places
    });

    const SalesTab = () => {
        setTab1(true);
        setTab2(false);
        setSalesTab(true);
        setPurchases(false);
    }
    const PurchaseTab = () => {
        setTab1(false);
        setTab2(true);
        setSalesTab(false);
        setPurchases(true);

    }
    useEffect(() => {
        setSalesTab(true);
    }, [])

    const openOrder = async (item) => {
        try {
            const querySnapshot = await getDocs(collection(firestore, "Users", currentUser.id, "Sales", formattedmonthYear, formattedDate));

            const emps = querySnapshot.docs.map((doc) => doc.data());

            const ordNo = emps.map((doc) => doc.orderNo);

            ordNo.forEach(ele => {
                if (item.orderNo === ele) {
                    // console.log(item.date)

                    if (typeof item.items === 'string') {
                        try {
                            const prvItms = JSON.parse(item.items);
                            setPreviewItems(prvItms)
                            setPreview(item)
                            checkPreviewState()


                            // console.log(prvItms)

                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                            // Handle parsing error if needed
                        }
                    }
                }

            });

        } catch (error) {
            console.error("Error getting documents: ", error);
        }



    }

    const timestamp = preview?.date
        ? new Date(preview.date.seconds * 1000 + preview.date.nanoseconds / 1000000)
        : null;

    // Format the timestamp as "DD MMM YYYY, HH:MM:SS"
    const reviewoptions = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const formattedPreviewDateTime = timestamp
        ? timestamp.toLocaleString('en-NG', reviewoptions)
        : 'N/A';

    const checkPreviewState = () => {
        if (previewState) {
            setPreviewState(false)
        } else {
            setPreviewState(true)
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(sales);
    // Event handler for input change
    const handleInputChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);

        // Check if the search term is empty
        if (value.trim() === '') {
            // If it's empty, display all items
            setFilteredData(sales);
        } else {
            // If not empty, filter the data based on the search term
            const filteredItems = sales.filter((item) =>
                item.orderNo.toLowerCase().includes(value.toLowerCase())
            );

            // Update the state with the filtered data
            setFilteredData(filteredItems);
        }
    };

    const daysRange = [
        'Past 3 Days',
        'Past 7 Days',
        'Past 30 Days',
    ];

    const [selectedDaysRange, setSelectedDaysRange] = useState(''); // State to store the selected color

    const handleDaysChange = (event) => {
        setSelectedDaysRange(event.target.value);
    };

    return (
        <div className='pos-sales-container'>
            <div className='page-url-cont'>
                <div className='page-url'>
                    {/* <h1>POS Sales</h1> */}
                    <Link to={'/app/overview'}><span className='colored'>Overview</span></Link>
                    <span className='slash'>/</span>
                    <span className='mainurl'>POS Sales</span>
                </div>
            </div>

            <div className='column1'>
            
                <div className='each-box'>
                    <p className='box-head'>Today's Sales</p>
                    <p className='box-content'>₦{formattedTotalSales}</p>
                </div>

            </div>

            {preview && previewState && (
                <div className='preview-container'>
                    <div className='dark-overlay' onClick={checkPreviewState}></div>
                    <div className='preview-inner-container'>
                        <div className='closeBtn'>
                            <Icon.XCircle color='#007558' stroke-width='3'
                                onClick={checkPreviewState}
                            />
                        </div>
                        <ul>
                            <li>
                                Date: {formattedPreviewDateTime}
                            </li>
                            <li>
                                Transaction ID: {preview.orderNo}
                            </li>

                            <li>
                                PaymentMethod: {preview.paymentMethod}
                            </li>


                            <li>
                                Sold By: {preview.employee}
                            </li>

                            <li>
                                Total: ₦{preview.subTotal}
                            </li>

                            <li>
                                Items
                            </li>

                            <li>
                                <div class="table-responsive">
                                    <table id="sales-tbl" class="table table-centered table-borderless table-hover m-0">
                                        <thead class="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Item</th>
                                                <th>Item ID</th>
                                                <th>Quantity</th>
                                                <th class="text-center">Unit Price</th>
                                                <th class="text-right">Total</th>
                                            </tr>
                                        </thead>
                                        {previewItems.length > 0 && (
                                            <tbody>
                                                {previewItems.map((item, index) => (

                                                    <tr key={index}>
                                                        <th>{index + 1}</th>
                                                        <th>{item.item}</th>
                                                        <th>{item.itemId}</th>
                                                        <th>{item.quantity}</th>
                                                        <th>{item.unitprice}</th>
                                                        <th>{item.totalitemprice}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>
            )}


            <div className='pos-sales-content-container'>
                <div className='tab-controls'>
                    <ul>
                        <li>
                            <div onClick={SalesTab}>
                                <span className={salestab ? 'tab1 active' : 'tab1'}>
                                    Sales
                                </span>
                            </div>
                            {/* <div onClick={PurchaseTab}>
                                <span className={purchasetab ? 'tab1 active' : 'tab1'}>
                                    Purchases
                                </span>
                            </div> */}
                        </li>
                        

                        <li className='li-flex'>
                            <div>
                                <select
                                    id="colorSelector"
                                    value={selectedDaysRange}
                                    onChange={handleDaysChange}
                                >
                                    {/* <option value={selectedDaysRange}>{selectedDaysRange}</option> */}
                                    {daysRange.map((color) => (
                                        <option key={color} value={color}>
                                            {color}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className='flex'>
                                <input
                                    type="text"
                                    placeholder="Search by Trans ID"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                />

                                <div className='refresh_btn'>
                                    <Icon.RefreshCw color='#007558' stroke-width='3'
                                        onClick={fetchTodaySalesData}
                                    />
                                </div>
                            </div>
                        </li>

                       
                    </ul>

                    
                </div>
                <div class="tab-content">
                    <div class={salestab ? "tab-pane" : "tab-pane active"} id="sales">
                        <div class="table-responsive">
                            <table id="sales-tbl" class="table table-centered table-borderless table-hover m-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Transaction ID</th>
                                        <th>Customer</th>
                                        <th class="text-center">Payment Method</th>
                                        <th class="text-right">Total</th>
                                        <th class="text-right">Action</th>
                                    </tr>
                                </thead>
                                {sales.length > 0 ? (
                                    <tbody>
                                        {filteredData.map((item, key) => {
                                            const timestamp = new Date(item.date.seconds * 1000 + item.date.nanoseconds / 1000000); // Convert to milliseconds

                                            // Format the timestamp as "DD MMM YYYY, HH:MM:SS"
                                            const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
                                            const formattedDateTime = timestamp.toLocaleString('en-NG', options);

                                            return (
                                                <tr key={key}>
                                                    <th>{key + 1}</th>
                                                    <th>{formattedDateTime}</th>
                                                    <th>{item.orderNo}</th>
                                                    {item.customerName !== '' ? (
                                                        <th>{item.customername}</th>
                                                    ) : (
                                                        <th>N/A</th>
                                                    )}
                                                    <th>{item.paymentMethod}</th>
                                                    <th>₦{item.subTotal}</th>
                                                    <th>
                                                        <ul className='action-btn' onClick={() => openOrder(item)}>
                                                            Open
                                                        </ul>
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colspan="9" class="text-center"><br />
                                                    <div className='center'>
                                                        <img src="https://app.vendloop.com/assets/images/empty.svg" width="150" />
                                                        <br /><br />
                                                        <span class="font-bold">No data available in table.</span>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                    <div class={purchasetab ? "tab-pane" : "tab-pane active"} id="purchases">
                        <div class="table-responsive">
                            <table id="purchases-tbl" class="table table-centered table-borderless table-hover m-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Reference No</th>
                                        <th>Supplier</th>
                                        <th class="text-right">Amount</th>
                                        <th class="text-center">Purchase Status</th>
                                        <th class="text-center">Payment Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="7" class="text-center"><br />
                                            <div className='center'>
                                                <img src="https://app.vendloop.com/assets/images/empty.svg" width="150" />
                                                <br /><br />
                                                <span class="font-bold">No data available in table.</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default POSSales