import React from 'react'
import Signin from '../../Components/Signin';
import Footer from '../../Components/Footer';


function Login() {
  return (
    <>
      <Signin />
      {/* <Footer /> */}
    </>
  )
}

export default Login