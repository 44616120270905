import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { emailSignInStart, googleSignInStart } from '../../redux/User/user.actions';
// import ReactLoading from 'react-loading'
import Logo from '../../assets/mposlogofull.png'
import './styles.scss';
import AuthWrapper from '../AuthWrapper';
import FormInput from '../forms/FormInput';
import Button from '../forms/Button';
import img122 from '../../assets/referral.jpg';
import { auth } from '../../firebase/utils';
import bubble from "../../assets/animations/animation.json";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Dialogue from '../Dialogue';
import { fetchSignInMethodsForEmail, signInWithEmailAndPassword } from 'firebase/auth';
import * as Icon from 'react-feather';


const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const SignIn = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [click, setClick] = useState(false);
  const [errors, setErrors] = useState([]);
  const { currentUser, userErr } = useSelector(mapState);
  const [passtoggle, setPasstoggle] = useState(false);
  const [passtypetoggle, setPasstypetoggle] = useState(false);
  const [dialogue, setDialogue] = useState(false);


  const handleClick = () => {
    if (click) {
      setClick(false)
    } else {
      setClick(true)
    }
  };

  useEffect(() => {
    if (currentUser) {
      resetForm();
      // setErrors('verfied');
      history.push('/app/overview');
    }

  }, [currentUser]);

  const resetForm = () => {
    setEmail('');
    setPassword('');
  };

  const handleSubmit = e => {
    handleClick()
    setDialogue(true)
    if (email === '' || password === '') {
      setDialogue(false)
      setErrors('Email and Password is required')

    } else {
      fetchSignInMethodsForEmail(auth, email)
        .then((signInMethods) => {
          if (signInMethods.length) {

            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                if (user.emailVerified) {
                  dispatch(emailSignInStart({ email, password }));
                } else {
                  setClick(false);
                  setDialogue(false)
                  setErrors('Your email is not verified yet: A verification mail has been sent to your email, kindly check your inbox or spam')
                }

              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setErrors(errorMessage);
                setClick(false);
                setDialogue(false)
                // console.log("firebase error" + error)
              });

          } else {
            setClick(false);
            setDialogue(false)
            setErrors('This email is not registered with us')
          }
        })
        .catch((err) => {
          // console.log('firebase error' + err);
          if (err.code === 'auth/network-request-failed') {
            setErrors('No internet connection')
            setClick(false);
            setDialogue(false)
          }else{
            setClick(false);
            setDialogue(false)
            setErrors('Network Error')
          }
        });
    }

  }

  const hidePass = () => {
    setPasstoggle(!passtoggle);
    setPasstypetoggle(!passtypetoggle)
  }


  return (
    <div className='signinFlex'>
      {dialogue && (
        <Dialogue />
      )}

      {/* <AuthWrapper>
          <div className="formWrap-singin">
            <div className='logo-container'>
              <div className='logo'>
                <a href='/'>
                  <img src={Logo} alt='logo' />
                </a>

              </div>
            </div>

            <div className="txt">
              <span className='head'>
                Welcome back! Sign in to your account
              </span>
            </div>

            <ul className="err">
              <li>
                {errors}
              </li>
            </ul>

            <div className='signin-form-content'>

              <div className="each-input">
                <span className='input-heading'>Email</span>
                <div className='form-flex'>
                  <FormInput
                    type="text"
                    name="email"
                    value={email}
                    handleChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="each-input">
                <span className='input-heading'>Password</span>
                <div className='form-flex'>

                  <FormInput
                    type={passtypetoggle ? 'text' : 'password'}
                    name="password"
                    value={password}
                    minlength="4"
                    handleChange={e => setPassword(e.target.value)}
                  />
                  <button className='visibility-btn' onClick={hidePass}>
                    <div className='toggle-Icn'>
                      <i class={passtoggle ? "fa1 fa-eye active" : "fa1 fa-eye"}></i>

                      <i class={passtoggle ? "fa1 fa-eye-slash" : "fa1 fa-eye-slash active"}></i>
                    </div>
                  </button>
                </div>

              </div>

              <div className="links flex">
                <Link to="/recoverPassword">
                  Forgot Password?
                </Link>

              </div>

              <div className='span'>
                <Button type="submit" onClick={handleSubmit}>
                  <span >
                    Login
                  </span>
                </Button>
              </div>
              <div>

              </div>

              <div className="links center">
                <span>New here?</span>
                <Link to="/registration">
                  Register
                </Link>

              </div>

            </div>
          </div>
        </AuthWrapper> */}

      <div className='left-clm'>
        <div className='logo'>
          <a href='/'>
            <img src={Logo} alt='logo' />
          </a>

          {/* <h1>MPOS</h1> */}
        </div>
        <div className='left-center'>
          <div className='player'>
            <Player
              autoplay
              loop
              src={bubble}
              style={{ height: '70%', width: '70%' }}
            >
              <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
          </div>
        </div>
      </div>

      <div className='right-clm'>
        <div className='back_btn'>
          <Link to='/'>
            <Icon.ArrowLeftCircle size={30} color='#fff' />
          </Link>
        </div>
        <div className='right-clm-inner'>
          <h1>Welcome back! Sign in to your account</h1>

          <div className='signin-form-content'>
            <div className="each-input">
              <span className='input-heading'>Email</span>
              <div className='form-flex'>
                <FormInput
                  type="text"
                  name="email"
                  value={email}
                  handleChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="each-input">
              <span className='input-heading'>Password</span>
              <div className='form-flex'>

                <FormInput
                  type={passtypetoggle ? 'text' : 'password'}
                  name="password"
                  value={password}
                  minlength="4"
                  handleChange={e => setPassword(e.target.value)}
                />
                <button className='visibility-btn' onClick={hidePass}>
                  <div className='toggle-Icn'>
                    <i class={passtoggle ? "fa1 fa-eye active" : "fa1 fa-eye"}></i>

                    <i class={passtoggle ? "fa1 fa-eye-slash" : "fa1 fa-eye-slash active"}></i>
                  </div>
                </button>
              </div>

            </div>
            <span className='err'>{errors}</span>

            <div className="links flex">
              <Link to="/recoverPassword">
                Forgot Password?
              </Link>

            </div>

            <div className='span'>
              <Button type="submit" onClick={handleSubmit}>
                <span >
                  Login
                </span>
              </Button>
            </div>
            <div>

            </div>

            <div className="links center">
              <span>New here?</span>
              <Link to="/registration">
                Register
              </Link>

            </div>

          </div>

          <div className='right-clm-btm'>
            {/* <div className='logo'> */}
              {/* <img src={Logo} alt='logo' /> */}
              {/* <p>MPOS</p> */}
            {/* </div> */}

            <div className='flex'>
              <span>
                <a href='/'>Term of use </a> |  <a href='/'>Privacy policy</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
