import React, { Component } from "react";
import "./styles.scss";

class Calculatormodule extends Component {
  constructor() {
    super();
    this.state = {
      displayValue: "0",
      operator: null,
      waitingForOperand: false,
      currentValue: null,
    };
  }

  handleDigitClick = (digit) => {
    const { displayValue, waitingForOperand } = this.state;

    if (waitingForOperand) {
      this.setState({
        displayValue: String(digit),
        waitingForOperand: false,
      });
    } else {
      this.setState((prevState) => ({
        displayValue:
          prevState.displayValue === "0"
            ? String(digit)
            : prevState.displayValue + digit,
      }));
    }
  };

  handleOperatorClick = (newOperator) => {
    const { displayValue, operator, currentValue } = this.state;

    if (operator && !currentValue) {
      this.setState({
        operator: newOperator,
      });
      return;
    }

    if (currentValue !== null) {
      const result = this.calculate();
      this.setState({
        displayValue: String(result),
        operator: newOperator,
        currentValue: result,
        waitingForOperand: true,
      });
    } else {
      this.setState({
        currentValue: parseFloat(displayValue),
        waitingForOperand: true,
        operator: newOperator,
      });
    }
  };

  calculate = () => {
    const { displayValue, operator, currentValue } = this.state;
    const nextValue = parseFloat(displayValue);

    switch (operator) {
      case "%":
        return (currentValue / 100) * nextValue;
      case "+":
        return currentValue + nextValue;
      case "-":
        return currentValue - nextValue;
      case "\u00D7":
        return currentValue * nextValue;
      case "\u00F7":
        if (nextValue === 0) {
          alert("Can't divide by zero!");
          return 0;
        }
        return currentValue / nextValue;
      default:
        return nextValue;
    }
  };

  handleEqualsClick = () => {
    if (!this.state.operator) return;

    const result = this.calculate();
    this.setState({
      displayValue: String(result),
      operator: null,
      currentValue: result,
      waitingForOperand: true,
    });
  };

  handleClearClick = () => {
    this.setState({
      displayValue: "0",
      operator: null,
      waitingForOperand: false,
      currentValue: null,
    });
  };

  render() {
    return (
      <div className="calculator">
        <div
          className="display"
          style={{ maxWidth: "100%", overflowX: "auto" }}
        >
          {this.state.displayValue}
        </div>
        <div className="keypad">
          <button onClick={() => this.handleClearClick()} className="clear">
            AC
          </button>
          <button
            onClick={() => this.handleOperatorClick("%")}
            className="signs"
          >
            %
          </button>
          <button
            onClick={() => this.handleOperatorClick("\u00F7")}
            className="signs"
          >
            ÷
          </button>
          <button onClick={() => this.handleDigitClick(7)}>7</button>
          <button onClick={() => this.handleDigitClick(8)}>8</button>
          <button onClick={() => this.handleDigitClick(9)}>9</button>
          <button
            onClick={() => this.handleOperatorClick("\u00D7")}
            className="signs"
          >
            ×
          </button>
          <button onClick={() => this.handleDigitClick(4)}>4</button>
          <button onClick={() => this.handleDigitClick(5)}>5</button>
          <button onClick={() => this.handleDigitClick(6)}>6</button>
          <button
            onClick={() => this.handleOperatorClick("-")}
            className="signs"
          >
            -
          </button>
          <button onClick={() => this.handleDigitClick(1)}>1</button>
          <button onClick={() => this.handleDigitClick(2)}>2</button>
          <button onClick={() => this.handleDigitClick(3)}>3</button>
          <button
            onClick={() => this.handleOperatorClick("+")}
            className="signs"
          >
            +
          </button>
          <button onClick={() => this.handleDigitClick(0)}>0</button>
          <button onClick={() => this.handleDigitClick(".")}>.</button>
          <button onClick={() => this.handleEqualsClick()} className="equal">
            =
          </button>
        </div>
      </div>
    );
  }
}

export default Calculatormodule;
