import React from 'react'
import Footer from '../AdminManagement/Footer';
import Header from '../SuperUserDashboard/Header';

const SuperUserLayout = props => {
    return (
        <div className="fullHeight">
          <Header {...props} />
          <div>
            {props.children}
          </div>
          <Footer {...props} />
        </div>
      );
    };

export default SuperUserLayout