import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/messaging';
import { firebaseConfig } from './config';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, getFirestore, setDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const app = initializeApp(firebaseConfig);
// firebase.initializeApp(firebaseConfig);
// firebase.firestore().settings({ experimentalForceLongPolling: true });
const messaging = getMessaging(app);

export const auth = getAuth(app);
export const sendEmailVerification = getAuth(app);
export const firestore = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app);

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_MESSAGING_API_KEY })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        // console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      // console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then(function (registration) {
        return registration.scope;
      })
      .catch(function (err) {
        return err;
      });
  }

  
}

export const requestPermission = () => {

  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {

    if (permission === "granted") {

      // console.log("Notification User Permission Granted.");
      return getToken(messaging, { vapidKey: process.env.REACT_APP_MESSAGING_API_KEY })
        .then((currentToken) => {

          if (currentToken) {

            // console.log('Client Token: ', currentToken);
          } else {

            // console.log('Failed to generate the app registration token.');
          }
        })
        .catch((err) => {

          // console.log('An error occurred when requesting to receive the token.', err);
        });
    } else {

      // console.log("User Permission Denied.");
    }
  });

}

export const GoogleProvider = new GoogleAuthProvider(app);
GoogleProvider.setCustomParameters({ prompt: 'select_account' });

export const handleUserProfile = async ({ userAuth, additionalData }) => {
  if (!userAuth) return;
  const { uid } = userAuth;
  // console.log(uid)

  const Users = doc(firestore, 'Users', uid);
  const snapshot = await getDoc(Users);

  if (!snapshot.exists()) {
    // console.log('user does not exist')
    const { lastName,
      firstName,
      email,
      StoreName,
      Address,
      State,
      phone } = userAuth;
    const timestamp = new Date();
    const userRoles = ['Owner'];
    const subscription = 'trial';
    const EndDate = new Date();
    EndDate.setDate(EndDate.getDate() + 30);
    // EndDate.setMonth(EndDate.getMonth() + 1)
    const subEndDate = (EndDate);


    try {
      await setDoc((Users),{
        lastName,
        firstName,
        email,
        StoreName,
        Address,
        State,
        phone,
        createdDate: timestamp,
        subscription,
        subscriptionEndDate: subEndDate,
        userRoles,
        ...additionalData
      });
    } catch (err) {
      // console.log(err);
    }
  }

  return snapshot;
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  })
}