import moment from 'moment';
import { database, firestore } from './../../firebase/utils';


export const handleAddProduct = product => {

  return new Promise((resolve, reject) => {


    var pid = moment()
      .utcOffset('+01:00')
      .format('YYYYMDDhhmm');



    firestore
      .collection('Products')
      .doc(pid)
      .set(product)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })

    database
      .ref('Products')
      .child(pid)
      .set(product)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })

  });



}

export const handleAddOnsale = product => {

  return new Promise((resolve, reject) => {


    var pid = moment()
      .utcOffset('+01:00')
      .format('YYYYMDDhhmm');



    firestore
      .collection('onsale')
      .doc(pid)
      .set(product)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })

    database
      .ref('onsale')
      .child(pid)
      .set(product)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })

  });



}

export const handleFetchProducts = ({ startAfterDoc, persistProducts = [] }) => {
  return new Promise((resolve, reject) => {
    const pageSize = 5;

    let ref = firestore.collection('Products')
      .orderBy("createdDate", 'desc')
      .limit(pageSize);

    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);


    ref
      .get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...persistProducts,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleFetchOnsale = ({ startAfterDoc, persistProducts = [] }) => {
  return new Promise((resolve, reject) => {
    const pageSize = 5;

    let ref = firestore.collection('onsale')
      .orderBy("createdDate", 'desc')
      .limit(pageSize);

    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);


    ref
      .get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...persistProducts,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleFetchProductsFilter = ({ filterType, brandType, searchType, search, startAfterDoc, persistProducts = [] }) => {
  return new Promise((resolve, reject) => {
    const pageSize = 20;

    let ref = firestore.collection('Products')
      // .orderBy("createdDate", 'desc')
      .limit(pageSize);

    if (filterType) ref = ref.where("category", "==", filterType)
    if (brandType) ref = ref.where("pbrand", "==", brandType)
    // if (search) ref = ref.startAt(search);
    // .orderBy("createdDate", 'desc');
    if (searchType) ref = ref.where("pname", ">=", searchType);
      // .orderBy("createdDate", "desc");
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...persistProducts,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handlejustinFetchProducts = ({ justinvar, filterType, startAfterDoc, persistProducts = [] }) => {
  return new Promise((resolve, reject) => {
    const pageSize = 12;

    let justin = firestore.collection('Products').orderBy("createdDate", "desc").limit(pageSize);

    if (justinvar) justin = justin.startAt(justinvar);
    if (startAfterDoc) justin = justin.startAfter(startAfterDoc);

    justin
      .get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...persistProducts,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleDeleteProduct = documentID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('Products')
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, 2)
        resolve();
      })
      .catch(err => {
        reject(err);
      })

    database
      .ref('Products')
      .child(documentID)
      .delete()
      .then(() => {
        console.log(documentID, 2)
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleDeleteOnsale = documentID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('onsale')
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, 2)
        resolve();
      })
      .catch(err => {
        reject(err);
      })

    database
      .ref('onsale')
      .child(documentID)
      .delete()
      .then(() => {
        console.log(documentID, 2)
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchProduct = (productID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('Products')
      .doc(productID)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: productID
          });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleEditProduct = (productID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('Products')
      .doc(productID)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: productID
          });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}