import productsTypes from './products.types';
import productTypes from './products.types';
import productsftTypes from './products.types';


const INITIAL_STATE = {
  products: [],
  productsft: [],
  product: {}
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }

    case productsTypes.SET_PRODUCT:
      return {
        ...state,
        product: action.payload
      }

    case productsTypes.SET_FILTER_PRODUCTS:
      return {
        ...state,
        productsft: action.payload
      }


    default:
      return state;
  }
};

export default productsReducer;