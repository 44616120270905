import ordersTypes from './orders.types';

const INITIAL_STATE = {
  orderHistory: [],
  orderDetails: {},
  adminorderHistory: [],
  adminorderDetails: {},
  orderItems: [],
  adminorderTrack: [],
  adminorderTrackDetails: {},
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case
      ordersTypes.SET_USER_ORDER_HISOTRY:
      return {
        ...state,
        orderHistory: action.payload
      };

    case ordersTypes.SET_ADMIN_ORDER_HISOTRY:
      return {
        ...state,
        adminorderHistory: action.payload
      };

    case ordersTypes.SET_ADMIN_ORDER_TRACK:
      return {
        ...state,
        adminorderTrack: action.payload
      };

    case ordersTypes.SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload
      };

    case ordersTypes.SET_ADMIN_ORDER_DETAILS:
      return {
        ...state,
        adminorderDetails: action.payload,
        orderItems: action.payload
      };

    case ordersTypes.SET_ADMIN_ORDER_TRACK_DETAILS:
      return {
        ...state,
        adminorderTrackDetails: action.payload
      };

    default:
      return state;
  }
};

export default ordersReducer;
