import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  emailSignInStart,
  googleSignInStart,
} from "../../redux/User/user.actions";
// import ReactLoading from 'react-loading'
import Logo from "../../assets/mposlogofull.png";
import "./styles.scss";
import AuthWrapper from "../../Components/AuthWrapper";
import FormInput from "../../Components/forms/FormInput";
import Button from "../../Components/forms/Button";
import img122 from "../../assets/referral.jpg";
import { auth, firestore } from "../../firebase/utils";
import bubble from "../../assets/animations/animation.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Dialogue from "../../Components/Dialogue";
import { doc, getDoc } from "firebase/firestore";
import SuperUserHeader from "../Header";

const AdminLogin = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [click, setClick] = useState(false);
  const [errors, setErrors] = useState([]);
  const [passtoggle, setPasstoggle] = useState(false);
  const [passtypetoggle, setPasstypetoggle] = useState(false);
  const [dialogue, setDialogue] = useState(false);

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const docRef = doc(firestore, "SuperUser", email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (password === data.password) {
        console.log("Works");
        history.push("/admin/superuser/98765456789/dashboard");
        resetForm();
      } else {
        console.log("Email/password is incorrect");
      }
    } else {
      console.log("Incorrect Credentials");
    }
  };

  return (
    <>
      {/* <SuperUserHeader/> */}
      <section className="superUser">
      <section className="adminLogin"></section>
      <div className="loginCard">
        <h2>Log In</h2>
        <p>Log in to your account</p>
        <form>
          <div className="sign">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="sign">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button onClick={handleSubmit}>Submit</button>
        </form>
      </div>
    </section>
    </>

  );
};

export default AdminLogin;
