import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import * as Icon from 'react-feather';
import Button from '../forms/Button'
import './styles.scss'
import { Link, useHistory, NavLink} from 'react-router-dom';
import Logo from '../../assets/mposlogonew.png'
import Person from '../../assets/icons8-person-64.png'
import { ReactComponent as CloseMenu } from "../../assets/cancel.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import{IoMdCall} from 'react-icons/io'


function Navbar() {


  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 200) {

      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);




  //mobile responsiveness

const [Mobile, setMobile] = useState(false)



const [header, setHeader] = useState(false);

const changeBackgroundd = () => {
  if (window.scrollY >= 15) {

    setHeader(true);
  } else {
    setHeader(false);
  }
};
window.addEventListener('scroll', changeBackgroundd);



  return (
    <>
      <nav className={navbar ? 'ctn active' : 'ctn'}>

        <div className='top-nav'>
          <div className='top-nav-left'>
            <div className="each-left-head">
              <span>Talk to our specialist</span>
              , </div>
            <div className="each-left-head">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 64 64">

                <path d="M57.6 52.032v0c0 0.896-0.192 1.6-0.64 2.112-0.448 0.576-0.896 1.024-1.344 1.536-1.038 1.087-2.102 2.111-3.208 3.088l-0.056 0.048c-1.063 0.938-2.26 1.768-3.548 2.448l-0.1 0.048c-1.92 0.896-4.096 1.344-6.592 1.344s-4.8-0.448-6.912-1.344c-3.864-1.425-7.171-3.538-9.927-6.215l0.007 0.007c-2.819-2.68-5.416-5.521-7.806-8.535l-0.13-0.169c-2.143-3.278-3.239-4.883-4.345-6.482l1.017 1.554c-0.928-1.346-1.925-2.956-2.847-4.613l-0.161-0.315c-0.863-1.579-1.767-3.518-2.557-5.514l-0.131-0.374c-0.793-1.952-1.339-4.213-1.531-6.574l-0.005-0.082c-0.704-4.48-0.448-8.192 0.896-11.264 1.28-3.072 3.008-5.504 5.12-7.296 0.704-0.768 1.408-1.472 1.984-2.24 0.64-0.704 1.472-1.088 2.56-1.088 1.472 0 2.752 0.768 3.84 2.368 1.088 1.536 1.856 2.752 2.368 3.648 0.576 0.896 1.28 2.112 2.112 3.648 0.726 1.36 1.152 2.974 1.152 4.688 0 0.017-0 0.034-0 0.051v-0.003c-0.256 2.368-1.28 4.032-3.2 5.056-1.856 1.024-3.136 2.432-3.904 4.224-0.192 0.768-0.192 1.536-0.064 2.368s0.32 1.664 0.64 2.368c0.192 0.896 0.512 1.856 1.088 2.752s1.152 1.856 1.6 2.88c1.152 1.664 2.368 3.264 3.648 4.8 1.268 1.528 2.722 2.84 4.343 3.922l0.073 0.046c0.576 0.448 1.344 0.96 2.368 1.664 0.96 0.704 1.984 0.96 3.072 0.704 0.96-0.192 1.728-0.576 2.304-1.216 0.512-0.64 1.024-1.344 1.536-2.048 0.704-0.448 1.344-0.96 1.856-1.536 0.512-0.64 1.088-1.024 1.792-1.216 1.6-0.448 3.136-0.128 4.544 0.832s2.56 1.92 3.456 2.816c1.152 0.96 2.304 1.984 3.456 3.072 1.152 1.024 1.856 2.368 2.176 4.032z"></path>
              </svg>
              <a href="tel:+2349024232473" target="blank"  ><span>(+234) 902-423-2473</span></a>
            </div>
          </div>

          <div className='top-nav-right'>
            
            {/* <li className='nav-items signin'
            >
              <Link
                target="_blank" 
                to='/login' className='icons'>
                <span>SIGN IN</span>
              </Link>

            </li> */}

            <li className='nav-items register'>
              <Link
                // target="_blank" 
                to='/get-started' className='icons'>
                <span>GET STARTED</span>
              </Link>

            </li>
          </div>

        </div>

        <div className='btm-nav'>
          <div className='logo-container'>
            <div className='logo'>
              <a href='/'>
                <img src={Logo} alt='logo' />
              </a>

            </div>
          </div>

          <div className='nav-link-ctn'>
            <nav>
              <ul>
                <li>
                  <NavLink exact to="/" activeClassName='activeBg' >
                    About Us
                  </NavLink>
                </li>

                <li >
                  <NavLink exact to='/pos' activeClassName='activeBg'>
                   Mobile Point of Sale
                  </NavLink>
                </li>

                {/* <li >
                  <NavLink exact to='/store' activeClassName='activeBg'>
                   Create an Online Store
                  </NavLink>
                </li> */}

                <li>
                  <NavLink exact to='/pricing' activeClassName='activeBg'>
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink  exact to='/help' activeClassName='activeBg'>
                    Support
                  </NavLink>
                </li>

                <li>
                  <NavLink exact to='/download' activeClassName='activeBg'>
                    Download
                  </NavLink>
                </li>
             
                {/* <li>
                  <Link to="/login" >
                    <Icon.User color='#a2a2a2;'/>
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>

        </div>
      </nav>



      {/* mobile nav */}

      <header className={header ? 'ctnn activee' : 'ctnn'}>  

      <div className='nav'>

        <Link to="/" className="logo">
        <img src={Logo} alt="" />
        </Link>
      
        <ul className={Mobile ? "nav-links-mobile" : "nav-links"} onClick={() => setMobile(false)}>


        <li className="header1"> 
          <NavLink exact to="/" activeClassName='activeBgg'>
              About Us
          </NavLink>
          </li>

          <li className="header2"> 
          <NavLink exact to="/pos" activeClassName='activeBgg'>
           Mobile Point of Sale
          </NavLink>
          </li>

          <li className="header4">
          <NavLink exact to="/pricing" activeClassName='activeBgg'>
              Pricing
          </NavLink>
          </li>

          <li className="header5">
          <NavLink exact to="/help" activeClassName='activeBgg' >
              Support
              </NavLink>
            </li>

            <li className="header3">
              <NavLink exact to="/download" activeClassName='activeBgg' >
                Download
              </NavLink>
            </li>
    
            <li className="header6">
              <NavLink exact to="/get-started" activeClassName='activeBgg'>
             Get Started
          </NavLink>
          </li>

          {/* <li className="header7">
          <NavLink exact to="/login" activeClassName='activeBgg'>
             Login
          </NavLink>
          </li> */}

          <li className="header8">  
            <div className="specialistTalk">
              <IoMdCall/>
              <a href="tel:+2349024232473" target="blank"  ><span>(+234) 902-423-2473</span></a>
            </div>
          </li>
         
         

        </ul>
        
        {/* 
        whenever we click on button = setMobile(!Mobile) ==  is mobile oppsite to setMobile 
        */}
        <div className='mobile-menu-icon button' onClick={() => setMobile(!Mobile)}>
          {Mobile ? <CloseMenu className="bars" /> : <MenuIcon className="bars" />}
        </div>

    </div>

    </header>

    </>
  )
}

export default Navbar