import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss'
import { firestore } from '../../../firebase/utils';
import * as Icon from 'react-feather';
import { collection, getDocs } from '@firebase/firestore';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const Expenses = () => {
    const dispatch = useDispatch();
    const [tab1, setTab1] = useState(false);
    const [tab2, setTab2] = useState(false);
    const [expensesTab, setexpensesTab] = useState(false);
    const { currentUser, userErr } = useSelector(mapState);
    const [expenses, setExpenses] = useState([]);

    const today = new Date();

    // Define an array of month names in abbreviated form
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get the month, day, and year
    const day = today.getDate();
    const month = monthNames[today.getMonth()]; // Get abbreviated month name
    const year = today.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const fullyear = today.getFullYear().toString(); // Get the last two digits of the year

    // Combine the month and year in the desired format
    const formattedYear = `${fullyear}`;
    const formattedmonthYear = `${month},${year}`;
    const formattedDate = `${day} ${month} ${year}`;

    const fetchTodayExpensesData = async () => {
        try {

            const querySnapshot = await getDocs(collection(firestore, "Users", currentUser.id, "Expenses", formattedmonthYear, formattedDate));

            const emps = querySnapshot.docs.map((doc) => doc.data());

            setExpenses(emps);
            console.log(emps)

        } catch (error) {
            console.error("Error getting documents: ", error);
        }
    };

    useEffect(() => {
        fetchTodayExpensesData();
    }, []);

    const totalExpenses = expenses.reduce((sum, order) => {
        // Convert the subTotal to a number and add it to the sum
        return sum + parseFloat(order.amount);
    }, 0);

    // Format the totalSum as a money format
    const formattedTotalExpenses = totalExpenses.toLocaleString('en-US', {
        minimumFractionDigits: 2, // Specify the number of decimal places
    });

    const ExpensesTab = () => {
        setTab1(true);
        setTab2(false);
        setexpensesTab(true);
    }
    
    useEffect(() => {
        setexpensesTab(true);
    }, [])

    return (
        <div className='expenses-container'>
            <div className='page-url-cont'>
                <div className='page-url'>
                    {/* <h1>POS Sales</h1> */}
                    <Link to={'/app/overview'}><span className='colored'>Overview</span></Link>
                    <span className='slash'>/</span>
                    <span className='mainurl'>Expenses</span>
                </div>
            </div>

            <div className='column1'>
                <div className='each-box'>
                    <p className='box-head'>Today's Expenses</p>
                    <p className='box-content'>₦{formattedTotalExpenses}</p>
                </div>
            </div>

            <div className='pos-sales-content-container'>
                <div className='tab-controls'>
                    <ul>
                        <li onClick={ExpensesTab}>
                            <span className={expensesTab ? 'tab1 active' : 'tab1'}>
                                Expenses
                            </span>
                        </li>
                        <Icon.RefreshCw color='#007558' stroke-width= '3' onClick={fetchTodayExpensesData}/>
                    </ul>
                    
                </div>
                <div class="tab-content">
                    <div class={expensesTab ? "tab-pane" : "tab-pane active"} id="sales">
                        <div class="table-responsive">
                            <table id="sales-tbl" class="table table-centered table-borderless table-hover m-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        {/* <th>ID</th> */}
                                        <th>User</th>
                                        <th class="text-center">Item</th>
                                        <th class="text-right">Amount</th>
                                        {/* <th class="text-right">Action</th> */}
                                    </tr>
                                </thead>
                                {expenses.length > 0 ? (
                                    <tbody>
                                        {expenses.map((item, key) => {
                                            const timestamp = new Date(item.date.seconds * 1000 + item.date.nanoseconds / 1000000); // Convert to milliseconds

                                            // Format the timestamp as "DD MMM YYYY, HH:MM:SS"
                                            const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
                                            const formattedDateTime = timestamp.toLocaleString('en-NG', options);
                                            return (
                                                <tr key={key}>
                                                    <th>{key}</th>
                                                    <th>{formattedDateTime}</th>
                                                    {/* <th>{item.id}</th> */}
                                                    <th>{item.employee}</th>
                                                    <th>{item.item}</th>
                                                    <th>{item.amount}</th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colspan="9" class="text-center"><br />
                                                    <div className='center'>
                                                        <img src="https://app.vendloop.com/assets/images/empty.svg" width="150" />
                                                        {/* <br /><br /> */}
                                                        <span class="font-bold">No data available in table.</span>
                                               </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expenses