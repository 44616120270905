import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './AdminLayout.scss';
import Header from '../AdminManagement/Header';
import Footer from '../AdminManagement/Footer';
import SideNav from '../AdminManagement/SideNav';
import { signOutUserStart } from '../redux/User/user.actions';


const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const AdminLayout = props => {
    const dispatch = useDispatch();
    const { currentUser, userErr } = useSelector(mapState);

    const [subState, setSubState] = useState(false);
    const today = new Date();


    const checkSubscriptions = () => {
        const ctDate = currentUser.createdDate;

        const timestamp = ctDate
            ? new Date(ctDate.seconds * 1000 + ctDate.nanoseconds / 1000000)
            : null;

        // Format the timestamp as "DD MMM YYYY, HH:MM:SS"
        const reviewoptions = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        const formattedPreviewDateTime = timestamp
            ? timestamp.toLocaleString('en-NG', reviewoptions)
            : 'N/A';

        const crtedDate = new Date(formattedPreviewDateTime);;

        // Calculate the time difference in milliseconds
        const timeDifference = today.getTime() - crtedDate.getTime();

        // Calculate the number of days
        const daysDifference = timeDifference / (24 * 60 * 60 * 1000);

        // Check if it's more than 30 days ago
        if (daysDifference > 30 || currentUser === 'trial') {
            setSubState(false)
            // console.log('The second date is more than 30 days after the first date.', daysDifference);
        }
        // else if () {

        // }

    }

    useEffect(() => {
        checkSubscriptions();

    }, []);

    const logout = () => {
        dispatch(signOutUserStart())
    }

    return (
        <div className="adminLayout">
            {subState ? (
                <div className='subState'>
                    <div className='logout_btn'>
                        <button onClick={logout}>Log out</button>
                    </div>
                    <div className='subState_inner'>
                        <div className='texts'>
                            <p className='subState_value'>{currentUser.subscription}</p>

                            <p> You dont have an Active subscription!</p>

                            <p> You have used up your trial period. Kindly subscribe to a plan to continue using our service</p>

                       </div>
                        <div className='btn-flex'>
                            <div className='button_cont'>
                                <a href='/pricing'>
                                    <button>Upgrade</button>
                                </a>
                            </div>

                            <div className='button_cont'>
                                <a href='/get-started'>
                                    <button>Go Back</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ):(
                    <>
                        <SideNav />
                        <div className="controlPanel">
                            <Header {...props} />
                            <div className="content">
                                {props.children}
                            </div>
                            <Footer {...props} />
                        </div>
                    </>
            )}
            
        </div>
    );
};

export default AdminLayout;
