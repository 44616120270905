import React from 'react'
import progress from "../../assets/animations/progress.json";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import './styles.scss';

const Dialogue = () => {
  return (
      <div className='dialogue-loading'>
          <div className='player'>
              <Player
                  autoplay
                  loop
                  src={progress}
                  style={{ height: '30%', width: '30%' }}
              >
                  <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
              </Player>
          </div>
      </div>
  )
}

export default Dialogue