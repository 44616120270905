import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import "./style.scss";
import dashboard from "../../assets/MPOS dashboard2.svg";
import receipt from "../../assets/receipt.svg";
import search from "../../assets/search.svg";
import sms from "../../assets/sms.svg";
import trophy from "../../assets/trophy.svg";
import goods from "../../assets/goods.svg";
import database from "../../assets/Database.svg";

const Index = () => {
  return (
    <div className="hero-main-container">
      <section className="hero">
        <div>
          <h1 className="major">
            MPOS has all the resources you need to grow your business
          </h1>
          <p className="text">
            Sell, create an online store, take inventory, manage and monitor
            your inventory, accept payments, and pay bills.
          </p>
          <div className="buttons">
            <Link to="/get-started">
              <button className="free">Try for free</button>
            </Link>
            <Link to="/download">
              <button className="app">Download the App</button>
            </Link>
          </div>
        </div>
        <div className="dashboard">
          <img src={dashboard} alt="mpos dashboard" />
        </div>
      </section>

      <section className="feature">
        <div className="features_first">
          <h2>What you stand to gain with MPOS unique features? </h2>
          <p>MPOS makes it super easy for your customers to shop from you! </p>
        </div>

        <div className="cards_about">
          <div className="card">
            <div>
              <img src={receipt} alt="" />
            </div>
            <hr />
            <h3>Customized Receipt & Barcode</h3>
            <p>
              Generate customized receipts with your logo and business info, and
              use our mini printers to print out bar-codes for easy inventory of
              your products.
            </p>
          </div>
          <div className="card next">
            <div>
              <img src={sms} alt="" />
            </div>
            <hr />
            <h3>Send Bulk SMS to Customers</h3>
            <p>
              Send bulk sms to your customers to inform them of your new
              products, services, discount deals, and “a happy birthday” wish
              with their information in your database.
            </p>
          </div>
          <div className="card upper">
            <div>
              <img src={search} alt="" />
            </div>
            <hr />
            <h3>Easy Search</h3>
            <p>
              Easily search for your products in your inventory with the search
              feature. You can easily search for your customers in your database
              and their payments history.
            </p>
          </div>
          <div className="card">
            <div>
              <img src={trophy} alt="" />
            </div>
            <hr />
            <h3>Loyalty Rewards</h3>
            <p>
              Give every customer a loyalty card and reward them with points for
              every purchase they make. Maintain a great relationship with your
              customers by rewarding them with points for every purchase they
              make
            </p>
          </div>
          <div className="card next">
            <div>
              <img src={goods} alt="" />
            </div>
            <hr />
            <h3>Online Store Orders</h3>
            <p>
              Receive orders from your online store and manage them from your
              dashboard. Sync the data of your online store with your offline
              store seamlessly.
            </p>
          </div>
          <div className="card upper">
            <div>
              <img src={database} alt="" />
            </div>
            <hr />
            <h3>Track Your Suppliers, Supplies, Income and Expenditure</h3>
            <p>
              Track your suppliers and supplies with the suppliers inventory
              feature. Stay up-to-date with your income and expenditure figures
              with regular generated reports.
            </p>
          </div>
        </div>
      </section>

      <section className="support">
        <h2>Have a question?</h2>
        <h3>Our team of experts is happy to assist you.</h3>
        <hr />
        <div className="last">
          <button className="app">Contact us</button>
          <p>Or call +234 902 423 2473</p>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
};

export default Index;
