import React, { useState } from 'react';
import userIMG from './../../assets/user.png';
import Logo from './../../assets/mposlogo.png';
import './styles.scss';
// import Calculator from '../../Components/CalculatorApp';
import { useDispatch } from 'react-redux';
import { signOutUserStart } from '../../redux/User/user.actions';
import { Link } from 'react-router-dom';
import Subscription from '../Components/Subscription';
import Calculatormodule from '../../Components/Calculatormodule';
import * as Icon from 'react-feather';

const Header = () => {
    const dispatch = useDispatch();


    const signOut = () => {
        dispatch(signOutUserStart());
    };

    const [profileclick, setProfileclick] = useState(false);
    const [menuClick, setMenuClick] = useState(false);
    const [calculator, setCalculator] = useState(false);


    const ProfileClickbtn = () => {
        setProfileclick(!profileclick);
        setMenuClick(false);
    }
    const BtnClick = () => {
        setMenuClick(!menuClick)
        setProfileclick(false);
    }
    const NotificationClick = () => {
        setMenuClick(false)
        setProfileclick(false);
    }
    const openCalculator = () => {
        setCalculator(true)
        console.log('openCalculator')
    }

    const [subspopState, setSubsPopstate] = useState(false);

    const opensubspop = () => {
        if (subspopState) {
            setSubsPopstate(false)

        } else {
            setSubsPopstate(true)

        }
    }

    return (
        <>
            <Subscription subState={subspopState} onClose={() => opensubspop()} />

            {calculator && (
               <div className='calculator-cont'>
                    <div className='cal-overlay' onClick={() => setCalculator(false)}>
                    </div>
                    <div className='cal-cont'>
                        <Icon.XCircle color='red' size={30} className='closeBtn' onClick={() => setCalculator(false)} />
                        <Calculatormodule />
                    </div>

               </div>
            )}
            <div className='Admin-nav'>
                {/* <div className='float-left'>
                <div className='account-btn'>
                    <ul>
                        <li>
                            <div className="img">
                                <img src={Logo} />
                            </div>
                        </li>
                    </ul>
                </div>
            </div> */}

                <div className='float-right'>
                    <div className='notification-btn' onClick={NotificationClick}>
                        <ul>
                            <li>
                                <i class="fa fa-bell" aria-hidden="true"></i>
                                <div className='red-dot'></div>
                            </li>
                        </ul>
                    </div>

                    <div className='menu-icon' onClick={BtnClick}>
                        <ul className='menu-ul'>
                            <li className={menuClick ? 'li active' : 'li'}>
                                <i class="fa fa-th-large" aria-hidden="true"></i>
                            </li>
                        </ul>

                        <ul className={menuClick ? 'hidden-nav' : 'hidden-nav active'}>
                            <li onClick={openCalculator}>
                                <i class="fa fa-calculator" aria-hidden="true"></i>
                                <span>Calculator</span>
                            </li>
                            {/* <li>
                            <Calculator />
                        </li> */}
                        </ul>
                    </div>

                    <div className='account-btn' onClick={ProfileClickbtn}>
                        <ul className={profileclick ? 'acct-ul active' : 'acct-ul'}>
                            <li>
                                <i class="fa fa-user" aria-hidden="true"></i>
                            </li>
                        </ul>
                        <ul className={profileclick ? 'hidden-nav' : 'hidden-nav active'}>
                            <li>
                                <Link to='/app/companyprofile'>
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                    <span> Account</span>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => opensubspop()}>
                                        <i class="fa fa-money" aria-hidden="true"></i>
                                        <span> Subscription</span>
                                </Link>
                            </li>

                            <li>
                                <Link to='/help'>
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    <span> Help & Support</span>
                                </Link>
                            </li>

                            <li onClick={() => signOut()} className='sigoutlink'>
                                <Link>
                                    <i class="fa fa-power-off" aria-hidden="true"></i>
                                    <span className="signOut">
                                        Sign Out
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default Header
