import React, { useState, useEffect, useRef } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay";
import lottie from "lottie-web";
import dashboard from "../../assets/MPOS dashboard.svg";
import storeOne from "../../assets/Storeone.svg";
import card from "../../assets/card.svg";
import cardImage from "../../assets/Card image.svg";
import image from "../../assets/image.svg";
import image2 from "../../assets/image2.svg";
import payment from "../../assets/payment.svg";
import invoice from "../../assets/invoice.svg";
import customer from "../../assets/customer.svg";
import flag from "../../assets/Flags.svg";
import store from "../../assets/store.svg";
import storeLine from "../../assets/store-line.svg";
import clock from "../../assets/Clock.svg";
import cube2 from "../../assets/cube-02.svg";
import cube4 from "../../assets/cube-04.svg";
import profile from "../../assets/profile outlined.svg";
import briefcase from "../../assets/Briefcase.svg";
import question from "../../assets/question.svg";
import icon from "../../assets/Icon.svg";
import { messaging, onMessageListener } from "../../firebase/utils";
import { Toaster, toast } from "react-hot-toast";

const Hero = () => {
  const [toggleState, setToggleState] = useState(1);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const container = useRef(null);

  const container2 = useRef(null);

  const container3 = useRef(null);

  const container4 = useRef(null);

  const container5 = useRef(null);
  //Testing Git

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../assets/animations/animation.json"),
    });
    return () => instance.destroy();
  }, []);

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container2.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../assets/animations/animation2.json"),
    });
    return () => instance.destroy();
  }, []);

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container3.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../assets/animations/animation3.json"),
    });
    return () => instance.destroy();
  }, []);
  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container4.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../assets/animations/animation4.json"),
    });
    return () => instance.destroy();
  }, []);

  useEffect(() => {
    checkAppMsg();
    const instance = lottie.loadAnimation({
      container: container5.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../assets/animations/animation5.json"),
    });
    return () => instance.destroy();
  }, []);

  const checkAppMsg = () => {
    // requestPermission();
    const unsubscribe = onMessageListener().then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      toast.success(
        `${payload?.notification?.title}: ${payload?.notification?.body}`,
        {
          duration: 60000,
          position: "top-right",
        }
      );
    });
    return () => {
      unsubscribe.catch((err) => console.log("failed: ", err));
    };
  };

  return (
    <div className="hero-main-container">
      <Toaster />
      <section className="hero">
        <div>
          <h1 className="major">
            Online & Offline Point of Sale System for all Businesses.
          </h1>
          <p className="text">
            Manage your online or offline business seamlessly from any device of
            your choice.
          </p>
          <div className="buttons">
            <Link to="/get-started">
              <button className="free">Try for free</button>
            </Link>
            <Link to="/download">
              <button className="app">Download the App</button>
            </Link>
          </div>
        </div>
        <div className="dashboard">
          <img src={dashboard} alt="mpos dashboard" />
        </div>

        <div className="cards">
          <div className="one">
            <img src={storeOne} alt="" />
            <h2>Create an online store for your business</h2>
            <p>
              You can create an online store for your business in 5 seconds and
              start selling your products online.
            </p>
          </div>
          <div className="one second">
            <img src={card} alt="" />
            <h2>Manage your business seamlessly from anywhere, anytime.</h2>
            <p>
              Access and download a report of your sales, purchases, expenses,
              inventory and your staff’s performance wherever and whenever you
              need it.
            </p>
          </div>
          <div className="one third">
            <img src={cardImage} alt="" />
            <h2>Take stock of all your products across multiple stores</h2>
            <p>
              Manage your inventory across multiple stores and warehouses. Get
              real-time updates on stock levels and sales. And never run out of
              stock again.
            </p>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="first_feature">
          <div className="first_feature_text">
            <h2>A Complete Multiple Payment Options</h2>
            <p>
              Regardless of how your customer chooses to pay, your business can
              accept it. We offer a wide range of payment options including
              cash, card, mobile money, bank transfer and more. And generate a
              receipt immediately
            </p>
            <Link to="/get-started">
              <button className="free">Try for free</button>
            </Link>
          </div>
          <div className="image">
            <img src={image} alt="" />
          </div>
        </div>

        <div className="second_feature">
          <div>
            <h2>One Platform for all Your Business Needs</h2>
            <p>
              Get all the resources you need to grow your business MPOS is
              efficient, effective and the best tool to grow your businesses.
            </p>
          </div>
          <div className="image">
            <div className="first_bar">
              <div className="first_bar_title">
                <h2>Manage</h2>
              </div>
              <div className="first_bar_contents">
                <h3>Take Inventory</h3>
                <p>
                  Take a detailed stock of all your products across multiple
                  stores. Get real-time updates on stock levels and sales. And
                  never run out of stock again or run at a loss.
                </p>
              </div>
              <div className="first_bar_contents">
                <h3>Manage Products</h3>
                <p>
                  You can easily add, edit and remove products in your store(s).
                  Swiftly upload product images, descriptions and price.
                </p>
              </div>
              <div className="first_bar_contents">
                <h3>Loyalty Rewards</h3>
                <p>
                  Grow your business with our loyalty reward program. Reward
                  your customers with points and coupons for their purchases.
                </p>
              </div>
            </div>
            <div className="divide"></div>
            <div className="first_bar">
              <div className="first_bar_title two">
                <h2>Sell</h2>
              </div>
              <div className="first_bar_contents">
                <h3>Hardware Support</h3>
                <p>
                  We provide you with a Mobile Printer, Barcode Scanner, Cash
                  Drawer and to help you effectively sell your products
                </p>
              </div>
              <div className="first_bar_contents">
                <h3>Barcode & Receipts</h3>
                <p>
                  Generate a barcode for your products for easy identifications
                  and generate receipts for your customers.
                </p>
              </div>
              <div className="first_bar_contents">
                <h3>Payment & Rewards</h3>
                <p>
                  Accept multiple payment options, including cash, bank
                  transfer, credit and loyalty reward cards.
                </p>
              </div>
            </div>

            <div className="divide"></div>
            <div className="first_bar">
              <div className="first_bar_title three">
                <h2>Report</h2>
              </div>
              <div className="first_bar_contents">
                <h3>Report & Analytics</h3>
                <p>
                  Receive real-time and daily updates on your sales, purchases,
                  expenses, inventory and staff performance.
                </p>
              </div>
              <div className="first_bar_contents">
                <h3>Create Targets</h3>
                <p>
                  Create goals for your team, track their progress and get a
                  daily, monthly and yearly report of the top performers .
                </p>
              </div>
              <div className="first_bar_contents">
                <h3>Daily Detailed Rewards</h3>
                <p>
                  Receive and easily take a snapshot of your daily sales report
                  to help you track your progress and grow your business.
                </p>
              </div>
            </div>
            <div className="divide"></div>
            <div className="first_bar">
              <div className="first_bar_title four">
                <h2>Grow</h2>
              </div>
              <div className="first_bar_contents">
                <h3>Create Multiple Stores</h3>
                <p>
                  Make more sales by creating an online store for your business
                  in 5 seconds and start selling your products online and
                  offline.
                </p>
              </div>
              <div className="first_bar_contents">
                <h3>Business Expansion</h3>
                <p>
                  Significantly expand your online and offline business with our
                  wide range of tools. With MPOS, the sky is your limit.
                </p>
              </div>
              <div className="first_bar_contents">
                <h3>Go Global</h3>
                <p>
                  Our platform supports business operations all over the world.
                  We have a centralized data structure that allows you to manage
                  your business from anywhere.
                </p>
              </div>
            </div>
          </div>
          <div>
            <Link to="/get-started">
              <button className="free">Try for free</button>
            </Link>
          </div>
        </div>

        <div className="third_feature">
          <div className="third_feature_first">
            <h2>Need to take your business from 0-100? MPOS is the key. </h2>
            <p>
              With MPOS you can easily stay ahead of your competitors and run
              your business smoothly. Anywhere, Anytime
            </p>
          </div>
          <div className="card">
            <div className="payment">
              <div>
                <img src={payment} alt="" />
              </div>
              <hr />
              <h3>Multiple Payment Options</h3>
              <p>
                MPOS integrates multiple payment gateways for your business.
                Your customers can use NFC technology, an ATM card, direct bank
                transfers or other options to pay, either online or offline.
              </p>
            </div>
            <div className="payment invoice">
              <div>
                <img src={invoice} alt="" />
              </div>
              <hr />
              <h3>Invoices and Receipts</h3>
              <p>
                You can print invoices and receipts from your POS or send
                directly to your customers’ inboxes. We have a flexible payment
                plan and variety of choices for mobile printers and barcode
                printers.
              </p>
            </div>
            <div className="payment customer">
              <div>
                <img src={customer} alt="" />
              </div>
              <hr />
              <h3>Customer Information</h3>
              <p>
                Easily store your customers information/sales history and
                retrieve them easily when needed. Send automated holiday
                celebration wishes and discount deal announcements with ease.
              </p>
            </div>
          </div>
        </div>

        <div className="go-global">
          <div>
            <h3>Grow Your Business with MPOS and Go Global!</h3>
            <p>
              With MPOS, you create an automated sales system for your business
              and a beautiful experience for your customers. Our software helps
              you keep an up-to-date database, structured financial record and
              powerful analytics. Which leads to an all-round business growth.
            </p>
          </div>
          <div className="map">
            <img src={flag} alt="varying flags" />
          </div>
          <div>
            <Link to="/features">
              <button className="free">More features</button>
            </Link>
          </div>
        </div>

        <div className="management">
          <div className="one">
            <img src={store} alt="online store" />
          </div>
          <div className="management_text">
            <div>
              <h2>
                Stay ahead of the game with our advanced management & financial
                tools.
              </h2>
            </div>
            <div className="management_text_features">
              <h3>Features</h3>
              <div className="management_text_features_list">
                <div className="lists">
                  <img src={storeLine} alt="" />
                  <p>
                    Manage multiple online and offline stores, anywhere, anytime
                  </p>
                  <hr className="rule" />
                </div>
                <div className="lists">
                  <img src={cube2} alt="" />
                  <p>Generate and print bar-codes and receipts. </p>
                  <hr className="rule" />
                </div>
                <div className="lists">
                  <img src={profile} alt="" />
                  <p>Create accounts for your employees & give them access. </p>
                  <hr className="rule" />
                </div>
                <div className="lists">
                  <img src={clock} alt="" />
                  <p>Keep an up-to-date inventory of your products. </p>
                  <hr className="rule" />
                </div>
                <div className="lists">
                  <img src={cube4} alt="" />
                  <p>Maintain a list of your orders and suppliers. </p>
                  <hr className="rule" />
                </div>
                <div className="lists">
                  <img src={briefcase} alt="" />
                  <p>Control your business finances and accept payments. </p>
                  <hr className="rule" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-us">
          <div className="contact-us_text">
            <h2>Have a question?</h2>
            <h3>Our team of experts is happy to assist you</h3>
            <p className="text">
              Our team is available 24/7 to help you with any issues you may
              have. Check out our detailed FAQs and help articles that can help
              you fix minor issues all by yourself.
            </p>
            <hr />
            <div className="last">
              <button className="app">Contact us</button>
              <p>Or call +234 902 423 2473</p>
            </div>
          </div>
          <div>
            <img src={question} alt="" />
          </div>
        </div>

        <div className="get-started">
          <h2>Let's get you started with MPOS</h2>
          <p className="get-started_text">
            Create your free account in minutes and start selling with Mobile
            Point of Sale (MPOS)
          </p>
          <div className="get-started-check">
            <div>
              <img src={icon} alt="" />
              <p>Free 30-day trial</p>
            </div>
            <div>
              <img src={icon} alt="" />
              <p>No credit card required</p>
            </div>
          </div>
          <button className="free">Try for free</button>
        </div>
      </section>
    </div>
  );
};

export default Hero;
