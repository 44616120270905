import React from 'react';
import './styles.scss';
import logo from '../../assets/mposlogonew.png';
import instagram from '../../assets/instagram.png';
import twitter from '../../assets/twitter.png';
import tiktok from '../../assets/tiktok.png';
import linkedin from '../../assets/linkedin-svgrepo-com.svg';
// import * as Icon from 'react-feather';
import { Link } from 'react-router-dom/cjs/react-router-dom';



const Footer = () => {
    return (
        <footer>
            <section className='grid'>
                <div className='text1'>
                    <div className='logo'><img src={logo} alt=''/></div>
                    <div className='online'><p>Manage your online or offline business <p className='seam'>seamlessly from any device of your choice.</p></p></div>
                    <div className='flex-socials'>
                        <div className='socials'>
                            <a href='https://www.instagram.com/smarthivetech/'>
                                <img src={instagram} alt=''/>
                                {/* <Icon.Instagram color='#000' size={20} fill/> */}
                            </a>
                        </div>
                        <div className='socials'>
                            <a href='https://twitter.com/smarthivetech'>
                                <img src={twitter} alt=''/>
                            </a>
                        </div>
                        <div className='socials'>
                            <a href='https://www.tiktok.com/@smarthivetech'>
                                <img src={tiktok} alt=''/>
                            </a>
                        </div>
                        <div className='socials linkedin'>
                            <a href='https://www.linkedin.com/company/smarthivetech'>
                                <img src={linkedin} alt=''/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='grid2'>
                    <div>
                        <ul>
                            <li className='bold'>Company</li>
                            <li><Link to='/pos'>Mobile point of sale</Link></li>
                            <li><Link to='/pricing'>Pricing</Link></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li className='bold'>Legal</li>
                            <li><Link to='/terms'>Terms and conditions</Link></li>
                            <li><Link to='/privacy-policy'>Privacy policy</Link></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li className='bold'>Support</li>
                            <li><Link to='tel:+2349024232473'>Call</Link></li>
                            <li><Link to='/help'>Help centre</Link></li>
                        </ul>
                    </div>
                </div>
            </section>
            <div className='copyright'><span>&copy; 2023, MPOS All Rights Reserved.</span></div>
        </footer>
    )
}

export default Footer