import React from 'react';
import "./styles.scss";
import Logo from '../../assets/mposlogofull.png'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import bubble from "../../assets/animations/animation.json";
import { Link, NavLink } from 'react-router-dom';
import * as Icon from 'react-feather';

const GetStarted = () => {
    return (
        <div className='gt_strt-container'>
            <div className='left-clm'>
                <div className='logo'>
                    <NavLink to='/'>
                        <img src={Logo} alt='logo' />
                    </NavLink>

                    {/* <h1>MPOS</h1> */}
                </div>
                <div className='left-center'>
                    <div className='player'>
                        <Player
                            autoplay
                            loop
                            src={bubble}
                            style={{ height: '70%', width: '70%' }}
                        >
                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                    </div>
                </div>
            </div>

            <div className='right-clm'>
                <div className='back_btn'>
                    <Link to='/'>
                        <Icon.ArrowLeftCircle size={30} color='#fff'/>
                    </Link>
                </div>
                <div className='right-clm-inner'>
                    <h1>Get started</h1>

                    <div className='btn_flex'>
                        <button><NavLink to='/login'>Log in</NavLink></button>
                        <button><NavLink to='/registration'>Sign up</NavLink></button>
                    </div>

                    <div className='right-clm-btm'>
                        <div className='logo'>
                            <img src={Logo} alt='logo' />
                            {/* <p>MPOS</p> */}
                        </div>

                        <div className='flex'>
                            <span>
                                <NavLink to='/'>Term of use </NavLink> |  <NavLink to='/'>Privacy policy</NavLink>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetStarted