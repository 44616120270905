import productsTypes from './products.types';

export const addProductStart = productData => ({
  type: productsTypes.ADD_NEW_PRODUCT_START,
  payload: productData
});

export const addOnsaleStart = productData => ({
  type: productsTypes.ADD_NEW_ONSALE_START,
  payload: productData
});

export const fetchProductsStart = (filters = {}) => ({
  type: productsTypes.FETCH_PRODUCTS_START,
  payload: filters
});

export const fetchOnsaleStart = (filters = {}) => ({
  type: productsTypes.FETCH_ONSALE_START,
  payload: filters
});

export const justinfetchProductsStart = (filters = {}) => ({
  type: productsTypes.JUSTIN_FETCH_PRODUCTS_START,
  payload: filters
});

export const fetchProductStartWIthFilter = (filters = {}) => ({
  type: productsTypes.FETCH_PRODUCT_START_WITH_FILTER,
  payload: filters
});

export const setProducts = products => ({
  type: productsTypes.SET_PRODUCTS,
  payload: products
});

export const setFilterProducts = productsft => ({
  type: productsTypes.SET_FILTER_PRODUCTS,
  payload: productsft
});


export const deleteProductStart = productID => ({
  type: productsTypes.DELETE_PRODUCT_START,
  payload: productID
});

export const deleteOnsaleStart = productID => ({
  type: productsTypes.DELETE_ONSALE_START,
  payload: productID
});

export const editProductStart = productID => ({
  type: productsTypes.EDIT_PRODUCT_START,
  payload: productID
});

export const fetchProductStart = productID => ({
  type: productsTypes.FETCH_PRODUCT_START,
  payload: productID
});

// export const fetchProductStartWIthFilter = productID => ({
//   type: productsTypes.FETCH_PRODUCT_START_WITH_FILTER,
//   payload: productID
// });

export const setProduct = product => ({
  type: productsTypes.SET_PRODUCT,
  payload: product
});