import React, { useState } from 'react'
import PopUpClass from '../PopUpClass';
import Img1 from '../../assets/mposmob1.jpeg'
import Img2 from '../../assets/mposmob2.jpeg'
import Img3 from '../../assets/mposmob5.jpeg'
import Img4 from '../../assets/mposmob4.jpeg'

import './styles.scss'
import Button from '../forms/Button';
import { Link } from 'react-router-dom';

const Download = () => {
    const [popstat, setPopstat] = useState(false)
    const [poptitle, setPopTitle] = useState('')
    const [popmsg, setPopMsg] = useState('')

    function downloadApkFile() {

        setPopstat(true);
        setPopTitle('Downloading...');
        setPopMsg('Please wait while your file starts downloading in a moment. Thanks for downloading.')

        setTimeout(() => {
            setPopstat(false);

        }, 4000);

        // Define the URL of the APK file
        const apkFileUrl = 'https://mpos.smarthivetechsolution.com/downloads/mpos.apk'; // Replace with your APK file URL

        // Create a temporary anchor element for the download
        const a = document.createElement('a');
        a.href = apkFileUrl;
        a.target = '_blank'; // Open in a new tab
        a.download = 'mpos.apk'; // Define the desired file name (e.g., app.apk)

        // Programmatically trigger a click event on the anchor element
        a.click();
    }

    function showPop() {
        // setPopstat(true)

        // if (popstat) {
        setPopstat(true);
        setPopTitle('Error');
        setPopMsg('App not available at the moment. Come back later')

        setTimeout(() => {
            setPopstat(false);

        }, 4000);
        // }

    }
    return (
        <>
            <PopUpClass openBol={popstat} title={poptitle} message={popmsg} />
            <div className='downloads_contianer_main'>
                <div className='downloads_contianer'>
                    <div className='col-1'>
                        <div className='left'>
                            <h1>Download
                                MPOS App</h1>
                        </div>
                        <div className='right'>
                            <p>Simplify your sales processes with our user-friendly POS system. Effortlessly manage inventory, sales, and customer data from a single, intuitive platform.</p>
                            <span>By installing Mpos, you agree to our <Link to='/terms'>Terms</Link> & <Link to='/privacy-policy'>Privacy Policy</Link>.</span>
                        </div>
                    </div>

                    <div className='col-2'>
                        <div className='each-card'>
                            <div>
                                <p className='head'>Mobile and Tablet</p>

                                <h2 className='title'>ANDROID</h2>

                                <p className='desc'>Minimum Requirements (Version 2.23.12.75) Android OS 5.0 or above</p>
                            </div>

                            <button onClick={downloadApkFile}>Download</button>
                        </div>

                        <div className='each-card'>
                            <div>
                                <p className='head'>Mobile and Tablet</p>

                                <h2 className='title'>iOS</h2>

                                <p className='desc'>Minimum Requirements (Version 22.13.74) Requires iOS 12.0 or newer</p>
                            </div>

                            <button onClick={showPop}>Download</button>
                        </div>
                    </div>

                    <div className='col-3'>
                        <div className='left'>
                            <h1>
                                Already
                                downloaded?
                            </h1>

                            <p>Learn more about what you can do on Mpos.</p>

                            <Link to='learn-more'>
                            <Button>Learn More</Button>
                            </Link>
                        </div>

                        <div className='right'>
                            <div className='each-card'>
                                <img src={Img1} alt='' />
                            </div>
                            <div className='each-card'>
                                <img src={Img2} alt=''/>
                            </div>

                            <div className='each-card'>
                                <img src={Img3} alt=''/>
                            </div>

                            <div className='each-card'>
                                <img src={Img4} alt=''/>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            
            {/* <Footer /> */}
        </>
    )
}

export default Download