import React, { useState, useRef } from "react";
import "./styles.scss";
import Logo from "../../../assets/mposlogo.png";
import FormInput from "../../../Components/forms/FormInput";
import Button from "../../../Components/forms/Button";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { database, firestore, storage, auth } from "../../../firebase/utils";
import { useEffect } from "react";
import * as Icon from "react-feather";
import { collection, doc, getDocs, updateDoc } from "@firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "@firebase/storage";
import { async } from "q";
import PopUpClass from "../../../Components/PopUpClass";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const CompanyProfile = (props) => {
  const [popstat, setPopstat] = useState(false);
  const [poptitle, setPopTitle] = useState("");
  const [popmsg, setPopMsg] = useState("");
  const history = useHistory();
  const [profileTab, setprofileTab] = useState(true);
  const [paymentTab, setpaymentTab] = useState(false);
  const { currentUser, userErr } = useSelector(mapState);
  const [name, setName] = useState(currentUser.StoreName);
  const [email, setEmail] = useState(currentUser.email);
  const [phone, setPhone] = useState(currentUser.phone);
  const [address, setAddress] = useState(currentUser.Address);
  const [image, setImage] = useState("");
  const [storeImg, setStoreImg] = useState(currentUser.StoreImg);
  const [urls, setUrls] = useState("");
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [transactions, setTransactions] = useState([]);
  // const [imgDisplay, setImgDisplay] = useState(storeImg);

  const inputRef = useRef(null);

  useEffect(() => {
    if (!storeImg) {
      setStoreImg(Logo);
    }

    fetchTransactions();
  }, [storeImg]);

  const fetchTransactions = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(firestore, "Users", currentUser.id, "PaymentHistory")
      );
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());

        const emps = querySnapshot.docs.map((doc) => doc.data());
        const empsID = querySnapshot.docs.map((doc) => doc.id);

        const combinedData = emps.map((data, index) => ({
          ...data, // Spread the data from emps
          id: empsID[index], // Add the corresponding ID from empsID
        }));

        setTransactions(combinedData);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const openGallery = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    // console.log('fileObj is', fileObj);

    // 👇️ reset file input
    // event.target.value = null;

    // 👇️ is now empty
    // console.log(event.target.files);

    // 👇️ can still access file object here
    // console.log(fileObj);
    // console.log(fileObj.name);

    const [file] = event.target.files;
    setImage(event.target.files[0]);
    setStoreImg(URL.createObjectURL(file));
  };

  const saveChanges = () => {
    console.log("save start2");

    // const promises = [];
    const storageReff = ref(storage, `stores/${currentUser.id}`);

    const metadata = {
      contentType: "image/jpeg",
    };
    const uploadTask = uploadBytesResumable(storageReff, image, metadata);

    // promises.push(uploadTask);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);

        console.log("Upload is " + progress + "% done");
        // eslint-disable-next-line default-case
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.log("err log", error);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // eslint-disable-next-line default-case
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // console.log('File available at', downloadURL);

          const querySnapshot = doc(firestore, "Users", currentUser.id);

          await updateDoc(querySnapshot, {
            StoreName: name,
            Address: address,
            phone: phone,
            StoreImg: downloadURL,
          })
            .then(() => {
              setPopstat(true);
              setPopTitle("Success");
              setPopMsg("Update was successful");

              setTimeout(() => {
                setPopstat(false);

                // console.log("completed")
              }, 4000);
            })
            .catch((err) => setError(err.message));
        });
      }
    );

    // Promise.all(promises)
    //     .then(() => alert("Updated"))
    //     // .then(history.go)
    //     .catch((err) => console.log(err));
  };

  const ProfileTab = () => {
    setprofileTab(true);
    setpaymentTab(false);
  };
  const PaymentTab = () => {
    setprofileTab(false);
    setpaymentTab(true);
  };

  return (
    <>
      <PopUpClass openBol={popstat} title={poptitle} message={popmsg} />
      <div className="company-profile-cont">
        <div className="page-url-cont">
          <div className="page-url">
            <Link to={"/app/companyprofile"}>
              <span className="colored">Account Settings</span>
            </Link>
          </div>
        </div>

        <div className="profile-content">
          <div className="tab-controls">
            <ul>
              <li>
                <div onClick={ProfileTab}>
                  <span className={profileTab ? "tab1 active" : "tab1"}>
                    Store Profle
                  </span>
                </div>
                <div onClick={PaymentTab}>
                  <span className={paymentTab ? "tab1 active" : "tab1"}>
                    Transactions
                  </span>
                </div>
              </li>
            </ul>
          </div>
          {/* <span className='head'>Store Profile</span> */}

          {profileTab && (
            <div className="store-profile-content-main">
              <div className="store-profile-content">
                <div className="logo-settings padding-left">
                  <div className="logo-cont">
                    <span>Store Logo</span>
                  </div>

                  <div className="logo-cont" onClick={openGallery}>
                    {/* storeimg */}
                    <img src={storeImg} alt="Store Logo" />
                    {/* <i class="fa fa-building" aria-hidden="true"></i> */}
                  </div>

                  <input
                    style={{ display: "none" }}
                    type="file"
                    ref={inputRef}
                    onChange={handleFileChange}
                  />

                  <div className="logo-cont" onClick={openGallery}>
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </div>
                </div>

                <div className="other-settings padding-left">
                  <div className="each-input">
                    <span className="input-heading">Store Name</span>
                    <span className="red">*</span>
                    <div className="form-flex">
                      <FormInput
                        type="text"
                        name="nickname"
                        value={name}
                        handleChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="each-input">
                    <span className="input-heading">Phone</span>{" "}
                    <span className="red">*</span>
                    <div className="form-flex">
                      <FormInput
                        type="text"
                        name="nickname"
                        value={phone}
                        handleChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="other-settings padding-left">
                  <div className="each-input">
                    <span className="input-heading">Email</span>
                    <span className="red">*</span>
                    <div className="form-flex">
                      <FormInput
                        type="text"
                        name="nickname"
                        readonly
                        value={email}
                        // handleChange={e => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="each-input">
                    <span className="input-heading">Address</span>{" "}
                    <span className="red">*</span>
                    <div className="form-flex">
                      <FormInput
                        type="text"
                        name="nickname"
                        value={address}
                        handleChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Button onClick={saveChanges}>Update</Button>
            </div>
          )}

          {paymentTab && (
            <div class="tab-content">
              <div
                class={paymentTab ? "tab-pane" : "tab-pane active"}
                id="sales"
              >
                <div class="table-responsive">
                  <table
                    id="sales-tbl"
                    class="table table-centered table-borderless table-hover m-0"
                  >
                    <thead class="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Transaction ID</th>
                        <th>Amount</th>
                        <th class="text-center">Plan</th>
                        <th class="text-right">Status</th>
                      </tr>
                    </thead>
                    {transactions.length > 0 ? (
                      <tbody>
                        {transactions.map((item, key) => {
                          const timestamp = new Date(
                            item.date.seconds * 1000 +
                              item.date.nanoseconds / 1000000
                          ); // Convert to milliseconds

                          // Format the timestamp as "DD MMM YYYY, HH:MM:SS"
                          const options = {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          };
                          const formattedDateTime = timestamp.toLocaleString(
                            "en-NG",
                            options
                          );

                          return (
                            <tr key={key}>
                              <th>{key + 1}</th>
                              <th>{formattedDateTime}</th>
                              <th>{item.transaction_id}</th>
                              <th>₦{item.amount}</th>
                              <th>{item.plan}</th>
                              <th>{item.status}</th>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colspan="9" class="text-center">
                            <br />
                            <img
                              src="https://app.vendloop.com/assets/images/empty.svg"
                              width="150"
                            />
                            <br />
                            <br />
                            <span class="font-bold">
                              No data available in table.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
