import React from 'react';

const NoNavLayout = props => {
  return (
    <div className="full">
      <div className="main">
        {props.children}
      </div>

    </div>
  );
};

export default NoNavLayout;
