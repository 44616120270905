import React, { useEffect, useState } from 'react';

import Homepage from '../../AdminManagement/Homepage';
// import './styles.scss';
import Header from '../../AdminManagement/Header';

import Footer from '../../AdminManagement/Footer';
// import * as Icon from 'react-feather';

const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const AdminManagement = props => {

  return (
    <section className="admin-homepage">
      <>
        {/* <Header {...props} /> */}
        <Homepage {...props} />
        
      </>
    </section>
  );
};

export default AdminManagement;