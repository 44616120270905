import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/mposlogo.png';
import * as Icon from 'react-feather';
import Subscription from '../Components/Subscription';


const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const SideNav = () => {
    const dispatch = useDispatch();
    const { currentUser, userErr } = useSelector(mapState);
    const id = currentUser.id;
    const busId = id.slice(0, 9);
    const StoreName = currentUser.StoreName;
    const [subscription, setSubscription] = useState('Trial');
    const [trialMode, settrialMode] = useState(false);
    const [navState, setNavState] = useState(false);
    const [navState2, setNavState2] = useState(false);
    const [subspopState, setSubsPopstate] = useState(false);


    useEffect(() => {
        if (currentUser.subscription === 'trial') {
            settrialMode(true);
        } else {
            setSubscription(currentUser.subscription);
        }
    }, [])


    //navs
    const [overview, setOverview] = useState(false);
    const [company, setCompany] = useState(false);
    const [bulksales, setBulksales] = useState(false);
    const [possales, setPossales] = useState(false);
    const [giftcard, setGiftcard] = useState(false);
    const [deliveries, setDeliveries] = useState(false);
    const [returns, setReturns] = useState(false);
    const [purchases, setPurchases] = useState(false);
    const [quotations, setQuotations] = useState(false);
    const [expenses, setExpenses] = useState(false);
    const [products, setProducts] = useState(false);
    const [stockmanager, setStockmanager] = useState(false);
    const [usermanager, setUsermanager] = useState(false);
    const [pricingbilling, setPricingbilling] = useState(false);
    const [reports, setReports] = useState(false);
    const [activitylogs, setActivitylogs] = useState(false);
    const [helpsupport, setHelpsupport] = useState(false);

    useEffect(() => {
        setOverview(true)
    }, [])

    // if(){

    // }

    //Link Click action
    const overviewLink = () => {
        setOverview(true);
        setCompany(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const companyLink = () => {
        setCompany(!company);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }


    const BulkSales = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(true);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const POSSales = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(true);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const GiftCard = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(true);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Deliveries = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(true);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Returns = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(true);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Purchases = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(true);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Quotations = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(true);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Expenses = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(true);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Products = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(true);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Stockmanager = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(true);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Usermanager = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(true);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Pricingbilling = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(true);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Reports = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(true);
        setActivitylogs(false);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Activitylogs = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(true);
        setHelpsupport(false);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const Helpsupport = () => {
        setCompany(false);
        setOverview(false);
        setBulksales(false);
        setPossales(false);
        setGiftcard(false);
        setDeliveries(false);
        setReturns(false);
        setPurchases(false);
        setQuotations(false);
        setExpenses(false);
        setProducts(false);
        setStockmanager(false);
        setUsermanager(false);
        setPricingbilling(false);
        setReports(false);
        setActivitylogs(false);
        setHelpsupport(true);
        setLiClick(false);
        setLiClick3(false);
        setLiClick2(false);
    }

    const [liClick, setLiClick] = useState(false);
    const [liClick2, setLiClick2] = useState(false);
    const [liClick3, setLiClick3] = useState(false);
    const storeprofile = () => {
        setLiClick(true);
        setLiClick3(false);
        setLiClick2(false);
    }

    const storelocation = () => {
        setLiClick2(true);
        setLiClick(false);
        setLiClick3(false);
    }

    const linkeddevice = () => {
        setLiClick3(true);
        setLiClick2(false);
        setLiClick(false);
    }

    const handleNav = () => {

        if (!navState && !navState2) {
            setNavState(true)

        } else {
            setNavState(false)
            setNavState2(false)
        }
    }

    const handleNav2 = () => {

        if (!navState && !navState2) {
            setNavState(true)
            setNavState2(true)

        } else if (navState && navState2) {
            setNavState(false)
            setNavState2(false)
        }

    }

    const opensubspop = () => {
        if (subspopState) {
            setSubsPopstate(false)

        } else {
            setSubsPopstate(true)

        }
    }

    return (
        <>
            <Subscription subState={subspopState} onClose={() => opensubspop()} />
            <div className={navState ? "sidenav-container" : "sidenav-container active"} >
                {navState && (
                    <div className='overlay' onClick={handleNav}></div>
                )}
                <div className='nav-btn'>
                    <div className='nav-btn-cont' onClick={handleNav}>
                        {navState ? (
                            <div className='icon'>
                                <Icon.ArrowLeftCircle color='white' />
                            </div>
                        ) : (
                            <div className='icon'>
                                <Icon.Menu color='white' />

                            </div>
                        )}
                    </div>
                </div>
                <div className="sidenav-inner-container" onMouseEnter={handleNav2} onMouseLeave={()=> { setNavState(false); setNavState2(false)}}>

                    <div className='menu-list'>
                        <div className="userProfile">
                            <img src={Logo} alt=''/>
                            <div className="displayName">
                                <span className='bus-name'>{StoreName}</span>
                            </div>
                        </div>
                        <ul onClick={overviewLink}>
                            <li>
                                <Link to="/app/overview" className={overview ? 'overviewlink active' : 'overviewlink'} onClick={handleNav}>
                                <i class="fa fa-home" aria-hidden="true"></i>
                                    <span> Dashboard</span>
                                </Link>
                            </li>
                        </ul>

                        {/* <span className='header'> Your Business</span> */}
                        <ul>

                            <li onClick={POSSales}>
                                <Link className={possales ? 'companylink active' : 'companylink'} to="/app/pos-sales" onClick={handleNav}>
                                    <i class="fa fa-desktop" aria-hidden="true"></i>
                                    <span>POS Sales</span>
                                </Link>
                            </li>

                            {/* <li onClick={Deliveries}>
                                <Link className={deliveries ? 'companylink active' : 'companylink'} to="/app/overview">
                                    <i class="fa fa-motorcycle" aria-hidden="true"></i>
                                    <span>Deliveries</span>
                                </Link>
                            </li> */}
                            {/* <li onClick={Returns}>
                                <Link className={returns ? 'companylink active' : 'companylink'} to="/app/overview">
                                    <i class="fa fa-undo" aria-hidden="true"></i>
                                    <span>Returns</span>
                                </Link>
                            </li> */}
                            {/* <li onClick={Purchases}>
                                <Link className={purchases ? 'companylink active' : 'companylink'} to="/app/overview">
                                    <i class="fa fa-credit-card" aria-hidden="true"></i>
                                    <span>Purchases</span>
                                </Link>
                            </li> */}
                            {/* <li onClick={Quotations}>
                                <Link className={quotations ? 'companylink active' : 'companylink'} to="/app/overview/">
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    <span>Quotations</span>
                                </Link>
                            </li> */}
                            <li onClick={Expenses}>
                                <Link className={expenses ? 'companylink active' : 'companylink'} to="/app/expenses" onClick={handleNav}>
                                    <i class="fa fa-money" aria-hidden="true"></i>
                                    <span>Expenses</span>
                                </Link>
                            </li>
                            <li onClick={Products}>
                                <Link className={products ? 'companylink active' : 'companylink'} to="/app/products" onClick={handleNav}>
                                    <i class="fa fa-tag" aria-hidden="true"></i>
                                    <span>Products</span>
                                </Link>
                            </li>
                            {/* <li onClick={Stockmanager}>
                                <Link className={stockmanager ? 'companylink active' : 'companylink'} to="/app/overview">
                                    <i class="fa fa-archive" aria-hidden="true"></i>
                                    <span>Stock Manager</span>
                                </Link>
                            </li> */}
                            <li onClick={Usermanager}>
                                <Link className={usermanager ? 'companylink active' : 'companylink'} to="/app/usersmanager" onClick={handleNav}>
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                    <span>User Manager</span>
                                </Link>
                            </li>
                            {/* <li onClick={Pricingbilling}>
                                <Link className={pricingbilling ? 'companylink active' : 'companylink'} to="/app/overview/">
                                    <i class="fa fa-ticket" aria-hidden="true"></i>
                                    <span>Pricing & Billing</span>
                                </Link>
                            </li> */}
                            {/* <li onClick={Reports}>
                                <Link className={reports ? 'companylink active' : 'companylink'} to="/app/overview">
                                    <i class="fa fa-line-chart" aria-hidden="true"></i>
                                    <span>Reports</span>
                                </Link>
                            </li> */}
                            {/* <li onClick={Activitylogs}>
                                <Link className={activitylogs ? 'companylink active' : 'companylink'} to="/app/overview">
                                    <i class="fa fa-book" aria-hidden="true"></i>
                                    <span>Activity Logs</span>
                                </Link>
                            </li> */}
                            {/* <li onClick={Helpsupport}>
                                <Link className={helpsupport ? 'companylink active' : 'companylink'} to="/help&support">
                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                    <span>Help & Support</span>
                                </Link>
                            </li> */}

                            {navState && (
                                <li>
                                    <div className='upgrade-container'>
                                        <div>
                                            <span className='remove-padding'>Plan:</span>
                                            <span className='red'>{subscription}</span>
                                        </div>
                                        {subscription === 'PRO' ? (
                                            <></>
                                        ) : (
                                            <Link className='upgradeBtn' onClick={
                                                () => {
                                                    opensubspop();
                                                    handleNav();
                                                }
                                            } >
                                                <span>Upgrade</span>
                                            </Link>
                                        )}
                                    </div>
                                </li>
                            )}

                            {/* <li>
                                <span className="signOut" onClick={() => signOut()}>
                                    Sign Out
                                </span>
                            </li> */}
                        </ul>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SideNav