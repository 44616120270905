import React, { useEffect, useState } from 'react'
import PopUpClass from '../../../Components/PopUpClass'
import Footer from '../../Footer'
import './styles.scss'
import StarterImg from '../../../assets/launcher.png'
import ProImg from '../../../assets/crown.png'
import TrialImg from '../../../assets/free-trial.png'

import * as Icon from 'react-feather';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useDispatch, useSelector } from 'react-redux'
import { firestore } from '../../../firebase/utils'
import { signOutUserStart } from '../../../redux/User/user.actions'
import { doc, setDoc, updateDoc } from 'firebase/firestore'

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const Subscription = ({ subState, onClose }) => {
    const [popstat, setPopstat] = useState(false)
    const [poptitle, setPopTitle] = useState('')
    const [popmsg, setPopMsg] = useState('')
    const { currentUser, userErr } = useSelector(mapState);
    const [subStat, setSubStat] = useState(false);
    const [selectedPlanAmount, setSelectedPlanAmount] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedPlanDesc, setSelectedPlanDesc] = useState('');
    const [subExpDate, setsubExpDate] = useState('');
    const [duration, setDuration] = useState('');

    const dispatch = useDispatch();
    const today = new Date();

    const signOut = () => {
        dispatch(signOutUserStart());
    };

    // console.log(openBol, title, message)

    const timestamp = currentUser?.subscriptionEndDate
        ? new Date(currentUser.subscriptionEndDate.seconds * 1000 + currentUser.subscriptionEndDate.nanoseconds / 1000000)
        : null;

    // Format the timestamp as "DD MMM YYYY, HH:MM:SS"
    const reviewoptions = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const formattedPreviewDateTime = today
        ? today.toLocaleString('en-NG', reviewoptions)
        : 'N/A';

    const expDate = new Date(formattedPreviewDateTime);

    const subExpDate1 = timestamp
        ? timestamp.toLocaleString('en-NG', reviewoptions)
        : 'N/A';
    const expDate1 = new Date(subExpDate1);

    useEffect(() => {
        if (subState) {
            setSubStat(true);
        } else {
            setSubStat(false);

        }
        setsubExpDate(expDate1.toDateString())

    }, [subState])


    const config = {
        public_key: 'FLWPUBK_TEST-5a5545b45c2f0cb866c2da78ee97e756-X',
        tx_ref: Date.now(),
        amount: selectedPlanAmount,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: currentUser.email,
            phone_number: currentUser.phone,
            name: currentUser.StoreName,
        },
        customizations: {
            title: selectedPlanDesc + "--" + currentUser.id,
            description: `Payment for ${selectedPlanDesc} from ${currentUser.id}`,
            logo: 'http://localhost:3000/static/media/mposlogo.6425eb12c4cb6bfd4c87.png',
        },
    };
    const handleFlutterPayment = useFlutterwave(config);


    async function callFlutApi() {
        // console.log("called flut API")
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate an asynchronous operation
        handleFlutterPayment({
            callback: async (response) => {
                console.log(response);
                closePaymentModal(); // this will close the modal programmatically

                if (response.charge_response_message === "Approved") {
                    // console.log("Payment approved")

                    const querySnapshot = doc(firestore, "Users", currentUser.id);

                    await updateDoc(querySnapshot,
                        {
                            subscription: selectedPlan,
                        })
                        .then(async () => {
                            // console.log("upadted user Plan")
                            await setDoc(doc(firestore, "Users", currentUser.id, "PaymentHistory", response.flw_ref), {
                                plan: selectedPlan,
                                amount: selectedPlanAmount,
                                date: today,
                                status: response.charge_response_message,
                                transaction_id: response.transaction_id,
                                tx_ref: response.tx_ref,
                                flw_ref: response.flw_ref,
                                charged_amount: response.charged_amount,
                            })
                                // .then(() => console.log("transac data success"))
                                .catch(err => console.log(err))
                        })
                        .then(async () => {
                            if (duration === 'Monthly') {
                                // console.log("selected", selectedPlan)

                                expDate.setDate(expDate.getDate() + 30);
                                const querySnapshot = doc(firestore, "Users", currentUser.id);

                                await updateDoc(querySnapshot,
                                    {
                                        subscriptionEndDate: expDate,

                                    })
                                    // .then(() => console.log("updated exp Date"))

                                    .catch(err => console.log(err))
                            } else {
                                // console.log("selected", selectedPlan)

                                expDate.setDate(expDate.getDate() + 365);
                                const querySnapshot = doc(firestore, "Users", currentUser.id);

                                await updateDoc(querySnapshot,
                                    {
                                        subscriptionEndDate: expDate,

                                    })
                                    // .then(() => console.log("updated exp Date"))

                                    .catch(err => console.log(err))
                            }
                            // const updatedDateString = expDate.toLocaleDateString(undefined, reviewoptions);
                            // console.log(expDate)

                        })
                        .then(() => {
                            setPopstat(true);
                            setPopTitle('Success');
                            setPopMsg('Payment was successful. Logging you out in a sec....');
                            setSubStat(false);

                            setTimeout(() => {
                                setPopstat(false);

                                // console.log("completed")
                                setSelectedPlanAmount(0)
                                setSelectedPlan('')
                                signOut()
                            }, 4000);
                        })
                        .catch(err => console.log(err.message));
                }
            },
            onClose: (err) => {
                setSelectedPlanAmount(0);
                // console.log(err)
                if (err) {
                    setPopstat(true);
                    setPopTitle('err:p78');
                    setPopMsg('Payment portal was closed');
                    setSubStat(false);

                    setTimeout(() => {
                        setPopstat(false);

                    }, 4000);
                }
            },
        });
    }


    useEffect(() => {
        if (selectedPlanAmount > 0) {
            callFlutApi();
        }
    }, [selectedPlanAmount]);

    return (
        <>
            <PopUpClass openBol={popstat} title={poptitle} message={popmsg} />
            {subStat && (
                <div className='subscription_contianer'>
                    <div className='darkoverlay' onClick={onClose}></div>
                    <div className='subscription_contianer-inner'>
                        <div className='header'>
                            <p>Mpos billing plans</p>
                            <Icon.X color='white' className='close_btn' onClick={onClose} />
                        </div>
                        <div className='col-1'>
                            <div className='each-card'>
                                <div>
                                    <img src={TrialImg} alt='' />
                                    <h2 className='title'>Trial</h2>
                                    <ul>
                                        <li>
                                            {/* <span><Icon.X color='#fff' /><p className='head'> Create an Online Store</p></span> */}
                                            <span><Icon.Check color='#fff' /><p className='head'> Manage 1 Offline Stores</p></span>
                                            <span><Icon.X color='#fff' /><p className='head'> Access Admin Tools</p></span>
                                            <span><Icon.X color='#fff' /><p className='head'> 1 Users</p></span>
                                            <span><Icon.Check color='#fff' /><p className='head'> Upload 10 Products</p></span>

                                        </li>
                                    </ul>


                                </div>

                                <div className='button-flex'>
                                    <div>
                                        <p>Free for 30days</p>
                                        {currentUser.subscription === 'trial' && (
                                            <div className='activeplan'>
                                                <p>Your current plan</p>
                                                <p className='p2'>Exp: {subExpDate}</p>
                                            </div>
                                        )}
                                        {/* <button onClick={() => {
                                            // setSelectedPlanAmount(2500);
                                            // setSelectedPlan("Starter")
                                            // setSelectedPlanDesc("Starter Plan - Monthly")

                                        }}
                                        >Monthly</button> */}
                                    </div>
                                </div>

                            </div>

                            <div className='each-card'>
                                <div>
                                    <img src={StarterImg} alt='' />
                                    <h2 className='title'>STARTER</h2>
                                    <ul>
                                        <li>
                                            {/* <span><Icon.Check color='#fff' /><p className='head'> Create an Online Store</p></span> */}
                                            <span><Icon.Check color='#fff' /><p className='head'> Manage 1 Offline Stores</p></span>
                                            <span><Icon.Check color='#fff' /><p className='head'> Access Admin Tools</p></span>
                                            <span><Icon.Check color='#fff' /><p className='head'> 3 User</p></span>
                                            <span><Icon.Check color='#fff' /><p className='head'> Upload 50 Products</p></span>

                                        </li>
                                    </ul>


                                </div>

                                <div className='button-flex'>
                                    {currentUser.subscription === 'Starter' ? (
                                        <div className='activeplan'>
                                            <p>Your current plan</p>
                                            <p className='p2'>Exp: {subExpDate}</p>

                                        </div>
                                    ) : (
                                        <>
                                            {currentUser.subscription === 'PRO' ? (
                                                <>
                                                </>
                                            ) : (
                                                <div className='button-flex-inner'>
                                                    <div>
                                                        <p>₦3,000</p>
                                                        <button onClick={() => {
                                                            setSelectedPlanAmount(3000);
                                                            setSelectedPlan("Starter")
                                                            setDuration("Monthly")
                                                            setSelectedPlanDesc("Starter Plan - Monthly")

                                                        }}
                                                        >Monthly</button>
                                                    </div>
                                                    <div>
                                                        <p>₦30,000</p>
                                                        <button onClick={() => {
                                                            setSelectedPlanAmount(30000);
                                                            setSelectedPlan("Starter")
                                                            setDuration("Yearly")
                                                            setSelectedPlanDesc("Starter Plan - Yearly")

                                                        }}>Yearly</button>
                                                    </div>
                                                </div>
                                            )}</>
                                    )}

                                </div>

                            </div>

                            <div className='each-card'>
                                <div>
                                    <img src={ProImg} alt='' />
                                    <h2 className='title'>PRO</h2>
                                    <p>{subExpDate}</p>

                                    <ul>
                                        <li>
                                            {/* <span><Icon.Check color='#fff' /><p className='head'> Create an Online Store</p></span> */}
                                            <span><Icon.Check color='#fff' /> <p className='head'> Manage 1 Offline Stores</p></span>
                                            <span><Icon.Check color='#fff' /> <p className='head'> Access Admin Tools</p></span>
                                            <span><Icon.Check color='#fff' /> <p className='head'> Unlimited Users</p></span>
                                            <span><Icon.Check color='#fff' /><p className='head'> Upload Unlimited products</p></span>
                                        </li>
                                    </ul>

                                </div>

                                <div className='button-flex'>
                                    {currentUser.subscription === 'PRO' ? (
                                        <div className='activeplan'>
                                            <p>Your current plan</p>
                                            <p className='p2'>Exp: {subExpDate}</p>

                                        </div>
                                    ) : (
                                        <div className='button-flex-inner'>
                                            <div>
                                                <p>₦5,000</p>
                                                <button onClick={() => {
                                                    setSelectedPlanAmount(5000);
                                                    setSelectedPlan("PRO")
                                                    setDuration("Monthly")
                                                    setSelectedPlanDesc("PRO Plan - Monthly")

                                                }}>Monthly</button>
                                            </div>
                                            <div>
                                                <p>₦50,000</p>
                                                <button onClick={() => {
                                                    setSelectedPlanAmount(50000);
                                                    setSelectedPlan("PRO")
                                                    setDuration("Yearly")
                                                    setSelectedPlanDesc("PRO Plan - Yearly")

                                                }}>Yearly</button>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>


                        </div>
                        <div className='close-btn'>
                            <button onClick={() => {
                                onClose();
                                setSelectedPlanAmount(0);
                            }}>Close</button>
                        </div>
                    </div>


                </div>
            )}
        </>
    )
}

export default Subscription