import ordersTypes from './orders.types';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  handleSaveOrder, handleSaveAdminOrder, handleGetUserOrderHistory,
  handleGetAdminOrderHistory, handleGetOrder, handleGetAdminOrder, handleGetAdminOrderTrack
} from './orders.helpers';
import { auth } from './../../firebase/utils';
import { clearCart } from './../Cart/cart.actions';
import { setUserOrderHistory, setOrderDetails, setAdminOrderHistory, setAdminOrderDetails, setAdminOrderTrack } from './orders.actions';

export function* getUserOrderHistory({ payload }) {
  try {
    const history = yield handleGetUserOrderHistory(payload);
    yield put(
      setUserOrderHistory(history)
    );

  } catch (err) {
    console.log(err);
  }
}

export function* getAdminOrderHistory({ payload }) {
  try {
    const adminhistory = yield handleGetAdminOrderHistory(payload);
    yield put(
      setAdminOrderHistory(adminhistory)
    );

  } catch (err) {
    console.log(err);
  }
}

export function* getAdminOrderTrack({ payload }) {
  try {
    const track = yield handleGetAdminOrderTrack(payload);
    yield put(
      setAdminOrderTrack(track)
    );

  } catch (err) {
    console.log(err);
  }
}

export function* onGetUserOrderHistoryStart() {
  yield takeLatest(ordersTypes.GET_USER_ORDER_HISTORY_START, getUserOrderHistory);
};

export function* onGetAdminOrderHistoryStart() {
  yield takeLatest(ordersTypes.GET_ADMIN_ORDER_HISTORY_START, getAdminOrderHistory);
};

export function* onGetAdminOrderTrackStart() {
  yield takeLatest(ordersTypes.GET_ADMIN_ORDER_TRACK_START, getAdminOrderTrack);
};

export function* saveOrder({ payload }) {
  try {
    const timestamps = new Date();
    yield handleSaveOrder({
      ...payload,
      orderUserID: auth.currentUser.uid,
      orderCreatedDate: timestamps
    });

    yield handleSaveAdminOrder({
      ...payload,
      orderUserID: auth.currentUser.uid,
      orderCreatedDate: timestamps
    });

    yield put(
      clearCart()
    )

  } catch (err) {
    // console.log(err);
  }
};

export function* onSaveOrderHistoryStart() {
  yield takeLatest(ordersTypes.SAVE_ORDER_HISTORY_START, saveOrder);
};

export function* getOrderDetails({ payload }) {
  try {
    const order = yield handleGetOrder(payload);
    console.log(order)
    yield put(
      setOrderDetails(order)
    )

  } catch (err) {
    // console.log(err);
  }
}

export function* getAdminOrderDetails({ payload }) {
  try {
    const order = yield handleGetAdminOrder(payload);
    console.log(order)
    yield put(
      setAdminOrderDetails(order)
    )

  } catch (err) {
    // console.log(err);
  }
}

export function* onGetOrderDetailsStart() {
  yield takeLatest(ordersTypes.GET_ORDER_DETAILS_START, getOrderDetails);
};

export function* onGetAdminOrderDetailsStart() {
  yield takeLatest(ordersTypes.GET_ADMIN_ORDER_DETAILS_START, getAdminOrderDetails);
};

export default function* ordersSagas() {
  yield all([
    call(onSaveOrderHistoryStart),
    call(onGetUserOrderHistoryStart),
    call(onGetAdminOrderHistoryStart),
    call(onGetAdminOrderTrackStart),
    call(onGetOrderDetailsStart),
    call(onGetAdminOrderDetailsStart),
  ])
}
