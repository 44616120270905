import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { firestore } from '../../firebase/utils';
// import { collection, doc, setDoc } from "firebase/firestore";
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import { collection, getDocs } from 'firebase/firestore';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const Homepage = () => {
    const { currentUser, userErr } = useSelector(mapState);
    const [employeeIds, setEmployeeIds] = useState([]);
    const [sales, setSales] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [datesArray, setDatesArray] = useState([]);
    const [subState, setSubState] = useState(false);

    const today = new Date();


    // Define an array of month names in abbreviated form
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get the month, day, and year
    const day = today.getDate();
    const formattedDay = day < 10 ? `0${day}` : day;
    const month = monthNames[today.getMonth()]; // Get abbreviated month name
    const year = today.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const fullyear = today.getFullYear().toString(); // Get the last two digits of the year

    // Combine the month and year in the desired format
    const formattedYear = `${fullyear}`;
    const formattedmonthYear = `${month},${year}`;
    const formattedDate = `${formattedDay} ${month} ${year}`;

    const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, "Users", currentUser.id, "Employees"));
            querySnapshot.forEach((doc) => {
                const emps = querySnapshot.docs.map((doc) => doc.data());
                const empsID = querySnapshot.docs.map((doc) => doc.id);

                const combinedData = emps.map((data, index) => ({
                    ...data, // Spread the data from emps
                    id: empsID[index], // Add the corresponding ID from empsID
                }));

                // setEmployeesLgt(emps.length)

                setEmployeeIds(combinedData);
                
            });
            // console.log(emps)
        } catch (error) {
            console.error("Error getting documents: ", error);
        }
    };

    // Fetch employee IDs and set them in the state
    const fetchTodaySalesData = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, "Users", currentUser.id, "Sales", formattedmonthYear, formattedDate));

            const emps = querySnapshot.docs.map((doc) => doc.data());

            setSales(emps);


        } catch (error) {
            console.error("Error getting documents: ", error);
        }
    };

    const fetchTodayExpensesData = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, "Users", currentUser.id, "Expenses", formattedmonthYear, formattedDate));

            const emps = querySnapshot.docs.map((doc) => doc.data());

            setExpenses(emps);
        } catch (error) {
            console.error("Error getting documents: ", error);
        }
    };

    const fetchDateArray = () => {
        // Get today's date
        const today = new Date();

        // Create an array to hold the dates
        const dates = [];

        // Loop to generate dates for the last 7 days
        for (let i = 0; i < 7; i++) {
            const currentDate = new Date(today);
            currentDate.setDate(currentDate.getDate() - i);
            // dates.reverse();
            dates.push(currentDate);
        }

        // Set the state with the generated dates
        // console.log(dates.map((date) => date.toDateString()))

        dates.reverse();

        setDatesArray(dates.map((date) => date.toDateString()));

    }


    const totalSales = sales.reduce((sum, order) => {
        // Convert the subTotal to a number and add it to the sum
        return sum + parseFloat(order.subTotal);
    }, 0);

    // Format the totalSum as a money format
    const formattedTotalSales = totalSales.toLocaleString('en-US', {
        minimumFractionDigits: 2, // Specify the number of decimal places
    });

    const totalExpenses = expenses.reduce((sum, order) => {
        // Convert the subTotal to a number and add it to the sum
        return sum + parseFloat(order.amount);
    }, 0);

    // Format the totalSum as a money format
    const formattedTotalExpenses = totalExpenses.toLocaleString('en-US', {
        minimumFractionDigits: 2, // Specify the number of decimal places
    });

    const daysRange = [
        'Past 3 Days',
        'Past 7 Days',
        'Past 30 Days',
    ];

    useEffect(() => {
        // Fetch employee IDs and set them in the state
        fetchData();
        fetchTodaySalesData();
        fetchTodayExpensesData();
        fetchDateArray();
        // checkSubscriptions();

    }, []);

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: false,
                // text: formattedYear,
                color: '#029752',
                // textSize: '40px',
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    const data1 = [
        100, 200, 1000, 1200, 500, 600, totalSales
    ];
    const data2 = [
        // 100, 1100, 1000, 1200, 200, 600, 200, 300, 900, 600, 1100, 1200
    ];

    // Replace these with your actual data arrays for each day of the week
    // const mondayData = [100, 200, 300, 400, 500, 600, 700];


    // const weekLabels = [
    //     'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
    // ];
    const labels = [
        'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
    ];

    // console.log(labels)


    // const weekdata = {
    //     weekLabels,
    //     datasets: [
    //         {
    //             label: 'Weekly',
    //             data: mondayData, // Replace with your actual data array for Monday
    //             borderColor: 'rgb(255, 99, 132)',
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //             yAxisID: 'y',
    //         },
    //     ],
    // };

    const data = {
        labels: datesArray,
        datasets: [
            {
                label: 'Expenses Data',
                data: data1,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
            },
            {
                label: 'Sales Data',
                data: data2,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y1',
            },
        ],
    };





    const [selectedMonth, setSelectedMonth] = useState(''); // State to store the selected color
    const [selectedDaysRange, setSelectedDaysRange] = useState(daysRange[1]); // State to store the selected color

    // List of color options


    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleDaysChange = (event) => {
        setSelectedDaysRange(event.target.value);
        const index = event.target.selectedIndex

        if (index === 0) {
            filterbyDays(3)
        } else if (index === 1) {
            filterbyDays(7)
        } else if (index === 2) {
            filterbyDays(30)
        }
    };

    const filterbyDays = (item) => {
        // console.log(item)
        // Get today's date
        const today = new Date();

        // Create an array to hold the dates
        const dates = [];

        // Loop to generate dates for the last 7 days
        for (let i = 0; i < item; i++) {
            const currentDate = new Date(today);
            currentDate.setDate(currentDate.getDate() - i);
            // dates.reverse();
            dates.push(currentDate);
        }

        // Set the state with the generated dates
        // console.log(dates.map((date) => date.toDateString()))

        dates.reverse();

        setDatesArray(dates.map((date) => date.toDateString()));
    }

    return (
        <div className='admin-home-container'>

            <div className='column1'>
                <div className='each-box'>
                   <Link to={'./pos-sales/'}> 
                   <p className='box-head'>Today's Sales</p>
                    <p className='box-content'>₦{formattedTotalSales}</p></Link>
                </div>

                <div className='each-box'>
                  <Link to={'./expenses/'}>
                    <p className='box-head'>Today's Expenses</p>
                    <p className='box-content'>₦{formattedTotalExpenses}</p></Link>
                </div>

                <div className='each-box'>
                    <p className='box-head'>Sales Target</p>
                    <p className='box-content'>₦0.00</p>
                </div>

                <div className='each-box'>
                    <Link to={'./usersmanager/'}>
                        <p className='box-head'>Employees</p>
                        <p className='box-content'>{employeeIds.length}</p></Link>
                </div>
            </div>

            <div className='column2'>
                <div className='head'>
                    <p>Overview</p>

                    <div className='filter_container'>
                        <div className='each-filt'>
                            <select
                                id="colorSelector"
                                value={month}
                                onChange={handleMonthChange}
                            >
                                {/* <option value="">{month}</option> */}
                                {monthNames.map((color) => (
                                    <option key={color} value={color}>
                                        {color}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='each-filt'>
                            <select
                                id="colorSelector"
                                value={selectedDaysRange}
                                onChange={handleDaysChange}
                            >
                                {/* <option value="">{selectedDaysRange}</option> */}
                                {daysRange.map((color) => (
                                    <option key={color} value={color}>
                                        {color}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='chart'>
                    <Line options={options} data={data} />
                </div>
            </div>

            {/* <Footer /> */}
        </div>
    )
}

export default Homepage