import { firestore } from './../../firebase/utils';

export const handleSaveOrder = order => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('orders')
      .doc()
      .set(order)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const handleSaveAdminOrder = order => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('adminorders')
      .doc()
      .set(order)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const handleGetUserOrderHistory = uid => {
  return new Promise((resolve, reject) => {
    let ref = firestore.collection('orders').orderBy('orderCreatedDate');
    ref = ref.where('orderUserID', '==', uid);

    ref
      .get()
      .then(snap => {
        const data = [
          ...snap.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({ data });
      })
      .catch(err => {
        reject(err);
      });


  });
};

export const handleGetAdminOrderTrack = uid => {
  return new Promise((resolve, reject) => {
    let ref3 = firestore.collection('adminorders').orderBy('orderCreatedDate');
    ref3 = ref3.where('orderUserID', '==', uid);

    ref3
      .get()
      .then(snap => {
        const data = [
          ...snap.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({ data });
      })
      .catch(err => {
        reject(err);
      });


  });
};

export const handleGetAdminOrderHistory = uid => {
  return new Promise((resolve, reject) => {
    let ref2 = firestore.collection('adminorders').orderBy('orderCreatedDate');
    // ref2 = ref2.where('orderUserID', '==', uid);

    ref2
      .get()
      .then(snap => {
        const data = [
          ...snap.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({ data });
      })
      .catch(err => {
        reject(err);
      });


  });
};


export const handleGetOrder = orderID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('orders')
      .doc(orderID)
      .get()
      .then(snap => {
        if (snap.exists) {
          resolve({
            ...snap.data(),
            documentID: orderID
          })
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleGetAdminOrder = orderID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('adminorders')
      .doc(orderID)
      .get()
      .then(snap => {
        if (snap.exists) {
          resolve({
            ...snap.data(),
            documentID: orderID
          })
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}
