import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { signUpUserStart } from '../../redux/User/user.actions';
import './styles.scss';
import Logo from '../../assets/mposlogofull.png'
import Checked from '../../assets/checked.png'
import AuthWrapper from '../AuthWrapper';
import FormInput from '../forms/FormInput';
import Button from '../forms/Button';
import { auth } from '../../firebase/utils';
import bubble from "../../assets/animations/animation.json";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import * as Icon from 'react-feather';


const mapState = ({ user }) => ({
    currentUser: user.currentUser,
    userErr: user.userErr
});

const Signup = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser, userErr } = useSelector(mapState);
    const [StoreName, setStoretName] = useState('');
    const [Address, setAddress] = useState('');
    const [State, setState] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState('');
    const [stage1, setStage1] = useState(false);
    const [stage2, setStage2] = useState(false);
    const [stage3, setStage3] = useState(false);
    const [passtoggle, setPasstoggle] = useState(false);
    const [passtypetoggle, setPasstypetoggle] = useState(false);
    const [comfpasstoggle, setcomfPasstoggle] = useState(false);
    const [comfpasstypetoggle, setcomfPasstypetoggle] = useState(false);
    const [stage1check, setStage1check] = useState(false);
    const [stage2check, setStage2check] = useState(false);
    const [stage3check, setStage3check] = useState(false);
    const [passerr, setpasserr] = useState(false);
    const checkpass = (e) => {
        setConfirmPassword(e.target.value)
        if (password !== e.target.value) {
            setpasserr(true);
        } else {
            setpasserr(false);
        }
        // checkpassmatch();
    }
    const openstage1 = () => {
        setStage1(false);
        setStage2(false);
        setStage1check(false);
    }
    const handleStage1 = () => {
        if (password !== confirmPassword) {
            setpasserr(true);
        } else {
            setStage1(true);
            setStage2(true);
            setStage1check(true);
        }
    };
    const hidePass = () => {
        setPasstoggle(!passtoggle);
        setPasstypetoggle(!passtypetoggle)
    }
    const hidecomfPass = () => {
        setcomfPasstoggle(!comfpasstoggle);
        setcomfPasstypetoggle(!comfpasstypetoggle)
    }
    const handleStage2 = () => {
        setStage1(true);
        handleFormSubmit();
    };
    const handleStage3 = () => {
        // setStage2(false)
        // setStage3(true)
        // setStage2check(true);
    };
    const [click1, setClick1] = useState(false);

    const reset = () => {
        setfirstName('');
        setlastName('');
        setEmail('');
        setPhone('');
        setPassword('');
        setConfirmPassword('');
        setErrors([]);
    };
    const handleChecker = () => {
        setClick1(true)
    };
    const handleFormSubmit = event => {
        if (stage1 === true) {
            fetchSignInMethodsForEmail(auth, email)
                .then((signInMethods) => {
                    if (signInMethods.length) {
                        setErrors("This email is already registered with us.")
                    }
                    else {
                        setStage2(false)
                        setStage3(true)
                        setStage2check(true);
                        dispatch(signUpUserStart({
                            lastName,
                            firstName,
                            email,
                            StoreName,
                            Address,
                            State,
                            phone,
                            password,
                            confirmPassword
                        }));
                    }
                })
                .catch((err) => {
                    console.log(err)
                });

        }
        else {
            console.log("error")
        }
    }
    const configAuthWrapper = {
        headline: 'Registration'
    };

    return (
        <div className='signupFlex' >
            <div className="right-formWrap">

                {/* <div className='theLogo-container'>
                    <div className='theLogo'>
                        <a href='/'>
                            <img src={Logo} alt='logo' />
                        </a>

                    </div>
                </div> */}
                <div className='back_btn'>
                    <Link to='/'>
                        <Icon.ArrowLeftCircle size={30} color='#fff' />
                    </Link>
                </div>

                <div className="txt">
                    <span className='head'>
                        Let's create your account
                    </span>

                    <div className='cont'>
                        <span >
                            Signing up for Mpos is fast and free - No credit card, no commitments or long-term contracts
                        </span>
                    </div>
                </div>

                <div className='progress-cont'>
                    <div className='stage-cont'>
                        <div className={stage1check ? 'stage1 active' : 'stage1'}>
                            <span>
                                1
                            </span>
                        </div>

                        <div className={stage1check ? 'checker' : 'checker active'} onClick={openstage1}>
                            <img className='checker-icon' src={Checked} alt='checker' />
                        </div>

                        <div className={stage2check ? 'stage2 active' : 'stage2'}>
                            <span>
                                2
                            </span>
                        </div>

                        <div className={stage2check ? 'checker2' : 'checker2 active'}>
                            <img className='checker-icon' src={Checked} alt='checker' />
                        </div>

                        <div className={stage3check ? 'stage3 active' : 'stage3'}>
                            <span>
                                3
                            </span>
                        </div>

                        <div className={stage3check ? 'checker3' : 'checker3 active'}>
                            <img className='checker-icon' src={Checked} alt='checker' />
                        </div>
                    </div>

                </div>

                <ul className="err">
                    <li>
                        {errors}
                    </li>
                </ul>


                {/* Stage1 form starts here */}
                <div className={stage1 ? 'stage1Content active' : 'stage1Content'}>

                    {/* <div class="exp-wrapper">
                        <input onChange={monthinp} autocomplete="off" class="exp" id="month" maxlength="2" pattern="[0-9]*" inputmode="numerical" placeholder="MM" type="text" data-pattern-validate />
                        <input onChange={yearinp} autocomplete="off" class="exp" id="year" maxlength="2" pattern="[0-9]*" inputmode="numerical" placeholder="YY" type="text" data-pattern-validate />
                    </div> */}

                    <div className='stage1Content-inner'>
                        <div className="each-input">
                            <span className='input-heading'>Email</span>
                            <div className='form-flex'>
                                <FormInput
                                    type="text"
                                    name="email"
                                    value={email}
                                    // placeholder="Email"
                                    handleChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>


                        <div className="each-input">
                            <span className='input-heading'>Password</span>
                            <div className='form-flex'>
                                <FormInput
                                    type={passtypetoggle ? 'text' : 'password'}
                                    name="password"
                                    value={password}
                                    minlength="4"
                                    // placeholder="Password"
                                    handleChange={e => setPassword(e.target.value)}
                                />
                                <button className='visibility-btn' onClick={hidePass}>
                                    <div className='toggle-Icn'>
                                        <i class={passtoggle ? "fa1 fa-eye active" : "fa1 fa-eye"}></i>

                                        <i class={passtoggle ? "fa1 fa-eye-slash" : "fa1 fa-eye-slash active"}></i>
                                    </div>
                                </button>
                            </div>

                        </div>

                        <div className="each-input">
                            <span className='input-heading'>Confirm your password</span>
                            <div className='form-flex'>
                                <FormInput
                                    type={comfpasstypetoggle ? 'text' : 'password'}
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    // placeholder="Confirm Password"
                                    handleChange={checkpass}
                                />
                                <button className='visibility-btn' onClick={hidecomfPass}>
                                    <div className='toggle-Icn'>
                                        <i class={comfpasstoggle ? "fa1 fa-eye active" : "fa1 fa-eye"}></i>

                                        <i class={comfpasstoggle ? "fa1 fa-eye-slash" : "fa1 fa-eye-slash active"}></i>
                                    </div>
                                </button>
                            </div>
                            <span className={passerr ? 'passerr' : 'passerr active'}>Password mismatch</span>
                        </div>

                        <div className="checkbox-container">
                            <label className="container">
                                <p>
                                    By signing up, you agree to the Mpos <Link to={"/terms&condition"} className="coloring">Terms of Service </Link>
                                    and Privacy and Cookie Notice
                                </p>
                            </label>
                        </div>

                        <Button onClick={handleStage1}>
                            <span className='nextbtn'>
                                Next
                            </span>
                        </Button>
                    </div>
                </div>
                {/* Stage1 form ends here */}


                {/* Stage2 form starts here */}
                <div className={stage2 ? 'stage2Content active' : 'stage2Content'}>

                    <div className="each-input">
                        <div className='input-heading'>Store Name</div>
                        <div className='form-flex'>
                            <FormInput
                                type="text"
                                name="nickname"
                                value={StoreName}
                                handleChange={e => setStoretName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="each-input">
                        <span className='input-heading'>Contact Information</span>
                        <div className='form-flex-cont'>
                            <div className='form-flex'>
                                <FormInput
                                    type="text"
                                    name="fname"
                                    value={firstName}
                                    placeholder="First name"
                                    handleChange={e => setfirstName(e.target.value)}
                                />
                            </div>

                            <div className='form-flex'>
                                <FormInput
                                    type="text"
                                    name="fname"
                                    value={lastName}
                                    placeholder="Last name"
                                    handleChange={e => setlastName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="each-input">
                        <span className='input-heading'>Phone</span>
                        <div className='form-flex'>
                            <FormInput
                                type="text"
                                name="fname"
                                value={phone}
                                handleChange={e => setPhone(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="each-input">
                        <span className='input-heading'>Address</span>
                        <div className='form-flex'>
                            <FormInput
                                type="address"
                                name="address"
                                value={Address}
                                handleChange={e => setAddress(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="each-input">
                        <span className='input-heading'>State</span>
                        <div className='form-flex'>
                            <select name='state' id="state" onChange={(e) => setState(e.target.value)}>
                                <option value="none" selected disabled hidden>
                                    Select State....
                                </option>
                                <option value="Abia">Abia</option>
                                <option value="Anambra">Anambra</option>
                                <option value="Bayelsa">Bayelsa  </option>
                                <option value="Ebonyi">Ebonyi </option>
                                <option value="Edo">Edo </option>
                                <option value="Ekiti">Ekiti  </option>
                                <option value="Enugu">Enugu   </option>
                                <option value="Imo">Imo   </option>
                                <option value="Lagos">Lagos </option>
                                <option value="Ogun">Ogun </option>
                                <option value="Osun">Osun  </option>
                                <option value="Oyo">Oyo   </option>

                            </select>
                        </div>
                    </div>

                    <div className="each-input">
                        <div className='form-flex'>
                            <span>NIGERIA</span>
                        </div>
                    </div>

                    {/* <div className="checkbox-container">
                        <label className="container">
                            <span>
                                I accept the <Link to={"/terms&condition"} className="coloring">Terms & Conditions </Link>
                                and Privacy and Cookie Notice
                            </span>
                        </label>
                    </div> */}

                    <Button onClick={handleStage2}>
                        <span className='nextbtn'>
                            ACTIVATE
                        </span>
                    </Button>
                </div>
                {/* Stage2 form ends here */}

                {/* Stage3 form starts here */}
                <div className={stage3 ? 'stage3Content active' : 'stage3Content'}>

                    <div className='head'>
                        You're almost done with your account registration
                    </div>

                    <div class="alert alert-info">
                        We have sent you an email with a verification link. To have access to your account, kindly verify your email account
                    </div>

                    <div className='loginbutton'>
                        <Button>
                            <Link to={'/login'} className='nextbtn'>
                                Login
                            </Link>
                        </Button>
                    </div>

                </div>
                {/* Stage3 form ends here */}


                <div className="links flex">
                    <li>
                        <span>Already Have An Account? </span>
                        <Link to="/login">
                            Signin
                        </Link>
                    </li>
                </div>
            </div>

            <div className='right-cont'>
                <div className='logo'>
                    <a href='/'>
                        <img src={Logo} alt='logo' />
                    </a>

                    {/* <h1>MPOS</h1> */}
                </div>
                <div className='left-center'>
                    <div className='player'>
                        <Player
                            autoplay
                            loop
                            src={bubble}
                            style={{ height: '70%', width: '70%' }}
                        >
                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;
