import React, { useState, useEffect } from "react";
import "./styles.scss";
import icon from "./icon-male.png";
import { firestore } from "../../firebase/utils";
import { collection, getDocs, getDoc, doc } from "@firebase/firestore";

const DashBoard = () => {
  const [users, setUsers] = useState(0);
  const [superUsers, setSuperUsers] = useState(0);
  const [versionNumber, setVersionNumber] = useState("");

  const fetchData = () => {
    const usersCollection = collection(firestore, "Users");
    const superUsersCollection = collection(firestore, "SuperUser");
    const versionDocRef = doc(firestore, "VersionControl", "mposv");

    getDocs(usersCollection)
      .then((querySnapshot) => {
        const numberOfUsers = querySnapshot.size;
        setUsers(numberOfUsers);
      })
      .catch((error) => {
        console.error("Error getting number of users: ", error);
      });

    getDocs(superUsersCollection)
      .then((querySnapshot) => {
        const numberOfSuperUsers = querySnapshot.size;
        setSuperUsers(numberOfSuperUsers);
      })
      .catch((error) => {
        console.error("Error getting number of superusers: ", error);
      });

    getDoc(versionDocRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const versionData = docSnapshot.data();
          const versionValue = versionData.Version;
          setVersionNumber(versionValue);
        }
      })
      .catch((error) => {
        console.error("Error getting document:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="superUserDashboard">
      <div className="heading">
        <h1>Application Dashboard</h1>
        <p>Home - Dashboard</p>
      </div>

      <div className="userContainer">
        <div className="totalUsers">
          <img src={icon} alt="" />
          <p className="number">{users}</p>
          <p className="text">Total Users</p>
        </div>
        <div className="totalUsers">
          <img src={icon} alt="" />
          <p className="number">{superUsers}</p>
          <p className="text">Total Super Users</p>
        </div>
        <div className="totalUsers">
          <img src={icon} alt="" />
          <p className="number">{versionNumber}</p>
          <p className="text">App version number</p>
        </div>
      </div>
    </section>
  );
};

export default DashBoard;
