import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const Footer = () => {
  return (
    <div className="footer-cont">
      <div className="left-float">
        <span>
          <Link to={"/aboutus"}>About Us</Link>
          <Link to={"/contactus"}>Contact Us</Link>
          <a href="./">Terms & Conditions</a>
        </span>
      </div>

      <div className="right-float">
        <span>
          <p className="copyright">
            Copyright © 2023 SmartHive Tech Solution. All rights reserved.
          </p>
        </span>
      </div>
    </div>
  );
};

export default Footer;
