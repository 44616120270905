import React, { useEffect, useState } from 'react';
import Directory from '../../Components/Hero';
// import ReactLoading from 'react-loading'
import './styles.scss';
import Footer from '../../Components/Footer';
import * as Icon from 'react-feather';


const Homepage = props => {


  return (
    <section className="homepage">
      <>
        <Directory />

        {/* <a className='fixed-nof'>

          <div className='loading'>

            <span className='bounce1'>
            </span>

          <span className='bounce2'>
          </span> 

          </div>

           <span>
            <Icon.MessageCircle size={40}/>
          </span> 
        </a> */}


        {/* <Footer /> */}
      </>
    </section>
  );
};

export default Homepage;