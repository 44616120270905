import React, { useEffect, useState } from 'react'
import './styles.scss'

const PopUpClass = ({openBol, title, message }) => {
    const [subStat, setSubStat] = useState(false);

    // console.log(openBol, title, message)

    useEffect(() => {
        if (openBol) {
            setSubStat(true);

            setTimeout(() => {
                setSubStat(false);

            }, 4000);
        }
    }, [openBol])
    
  return (
      <div className={subStat ? 'subStat_pop_up active' : 'subStat_pop_up'}>
          
          <div className='subStat_pop_up_inner'>
              <div className='red_dot'>

              </div>
              <p>{title}</p>
              <p>{message}</p>
          </div>

      </div>
  )
}

export default PopUpClass