import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../../Components/forms/Button';
import FormInput from '../../../Components/forms/FormInput'
import './styles.scss';
import { firestore } from '../../../firebase/utils';
import Dialogue from '../../../Components/Dialogue';
import PopUpClass from '../../../Components/PopUpClass';
import Subscription from '../Subscription';
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const UserManager = () => {
    const [popstat, setPopstat] = useState(false)
    const [poptitle, setPopTitle] = useState('')
    const [popmsg, setPopMsg] = useState('')
    const [AddNewUser, setAddNewUser] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [editedPassword, setEditedPassword] = useState("");
    const [editedUser, setEditedUser] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [UserName, setUserName] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const { currentUser, userErr } = useSelector(mapState);
    const [errors, setErrors] = useState([]);
    const [employeeIds, setEmployeeIds] = useState([]);
    const [dialogue, setDialogue] = useState(false);
    const [subStat, setSubStat] = useState(false);
    const [subspopState, setSubsPopstate] = useState(false);
    const [employeesLgt, setEmployeesLgt] = useState(0);


    const openAddUserPanel = () => {
        if (currentUser.subscription === 'trial') {
            setPopstat(true);
            setPopTitle('err:058');
            setPopMsg('Sorry, you can\'t create an employee. Kindly subscribe to a plan to create users')
            setTimeout(() => {
                setPopstat(false);

            }, 4000);

        } else if (currentUser.subscription >= 'Starter') {
            console.log(employeesLgt)
            if (employeesLgt === 3) {
                setPopstat(true);
                setPopTitle('err:059');
                setPopMsg('You have exceeded the amount of accounts you can create. Kindly subscribe to a PRO plan to get unlimited employees')
                setTimeout(() => {
                    setPopstat(false);

                }, 4000);

            } else {
                if (AddNewUser) {
                    setAddNewUser(false);
                } else {
                    setAddNewUser(true);
                }
            }
        } else {
            if (AddNewUser) {
                setAddNewUser(false);
            } else {
                setAddNewUser(true);
            }
        }

    }


    const closeAddUserPanel = () => {
        setAddNewUser(false);
        setUserName('');
        setPassword('');
        setConfirmPassword('');
    }
    const clearFields = () => {
        setUserName('');
        setPassword('');
        setConfirmPassword('');
    }

    const openEditUserPanel = (user) => {
      setEditUser(true);
      setEditedUser(user);
    };

    const closeEditUserPanel = () => {
      setEditUser(false);
      setEditedPassword("");
      setEditedUser(null);
    };

    const showDeleteConfirmation = (id) => {
      setDeleteUserId(id);
      setDeleteConfirmation(true);
    };

   

    // Fetch employee IDs and set them in the state
    const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, "Users", currentUser.id, "Employees"));
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const emps = querySnapshot.docs.map((doc) => doc.data());
                const empsID = querySnapshot.docs.map((doc) => doc.id);

                const combinedData = emps.map((data, index) => ({
                    ...data, // Spread the data from emps
                    id: empsID[index], // Add the corresponding ID from empsID
                }));

                setEmployeesLgt(emps.length)

                setEmployeeIds(combinedData);

            });



        } catch (error) {
            console.error("Error getting documents: ", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const sortedEmployeeIdsDescending = [...employeeIds].sort((a, b) => b.createdDate - a.createdDate);


    const createuser = async () => {
        setDialogue(true)
        if (Password === ConfirmPassword) {
            setErrors('');

            const usersCollection = collection(firestore, "Users");

            // Retrieve all user documents
            const userQuerySnapshot = await getDocs(usersCollection);

            // Iterate through each user document and query their "Employees" collection
            userQuerySnapshot.forEach(async (userDoc) => {
                const uid = userDoc.id;
                const employeesCollection = collection(firestore, "Users", uid, "Employees");

                // Query the "Employees" collection for the current user
                const employeesQuerySnapshot = await getDocs(employeesCollection);

                employeesQuerySnapshot.forEach(async (employeeDoc) => {
                    const data = employeeDoc.data();
                    console.log(data.username)
                    if (data.username === UserName) {
                        // console.log("Employee exist")  
                        setDialogue(false)
                        setPopstat(true);
                        setPopTitle('err:046');
                        setPopMsg('This name is taken. Try again.')
                        setTimeout(() => {
                            setPopstat(false);

                        }, 4000);
                    } else {
                        console.log("Employee does not exist")
                        const docRef = doc(firestore, "Users", currentUser.id, "Employees", UserName)
                        const docSnap = await getDoc(docRef);

                        if (docSnap.exists()) {
                            setErrors('Username is taken')
                            setDialogue(false)

                        } else {
                            // console.log('Creating');
                            await setDoc(doc(firestore, "Users", currentUser.id, "Employees", UserName), {
                                username: UserName,
                                password: Password,
                                companyID: currentUser.id,
                                userID: currentUser.id + "-" + UserName,
                                createdDate: Date.now()
                            })
                                .then(
                                    clearFields(),
                                    // console.log('Success'),
                                    setDialogue(false),
                                    fetchData(),
                                    openAddUserPanel(),
                                )
                                .catch(err => {
                                    console.log(err);
                                    setDialogue(false)

                                })
                        }
                    }


                });
            });



        }
        else {
            setDialogue(false)
            setErrors('Your password does not match. Try again')
            setPopstat(true);
            setPopTitle('err:046');
            setPopMsg('Password does not match.')
            setTimeout(() => {
                setPopstat(false);

            }, 4000);
        }
    }

    const updatePassword = async () => {
      setDialogue(true);
      if (editedPassword) {
        try {
          const docRef = doc(
            firestore,
            "Users",
            currentUser.id,
            "Employees",
            editedUser.id
          );
          await setDoc(docRef, { password: editedPassword }, { merge: true });
          setDialogue(false);
          closeEditUserPanel();
          fetchData();
        } catch (error) {
          console.error("Error updating password: ", error);
          setDialogue(false);
        }
      } else {
        setDialogue(false);
        setErrors("Password cannot be empty");
      }
    };

    const confirmDeleteUser = async () => {
      if (deleteUserId) {
        await deleteUser(deleteUserId);
        setDeleteConfirmation(false);
      }
    };

    const cancelDeleteUser = () => {
      setDeleteUserId(null);
      setDeleteConfirmation(false);
    };


    function showPop() {
        // setPopstat(true)

        // if (popstat) {

        setPopstat(true);
        setPopTitle('Hello!');
        setPopMsg('You don\'t have an active subsription! Kindly visit the subscription page to access to this module')
        setTimeout(() => {
            setPopstat(false);

        }, 4000);

        setTimeout(() => {
            opensubspop()

        }, 2000);
        // }

    }

    const opensubspop = () => {
        if (subspopState) {
            setSubsPopstate(false)

        } else {
            setSubsPopstate(true)

        }
    }

    const deleteUser = async (id) => {
        try {
            const docRef = doc(firestore, "Users", currentUser.id, "Employees", id)
            await
                deleteDoc(docRef)
                    .then(() => {
                        fetchData()
                    })
                    .catch(err => { console.log(err) });



        } catch (error) {
            console.error("Error getting documents: ", error);
        }
    }

    return (
      <>
        {dialogue && <Dialogue />}
        <PopUpClass openBol={popstat} title={poptitle} message={popmsg} />
        <Subscription subState={subspopState} onClose={() => opensubspop()} />

        <div className="user-manager-cont">
          <div className="page-url-cont">
            <div className="page-url">
              <Link to={"/app/overview"}>
                <span className="colored">Overview</span>
              </Link>
              <span className="slash">/</span>
              <span className="mainurl">User Manager</span>
            </div>
          </div>

          <button
            onClick={openAddUserPanel}
            className={AddNewUser ? "btn active" : "btn"}
          >
            Add New Employee
          </button>

          <div className="usermanager-content">
            <span className="head">Employees</span>

            {editUser && (
              <div className="addnewuser-container">
                <div className="overlay" onClick={closeEditUserPanel}></div>
                <div className="addnewuser-inner-container">
                  <ul className="err">
                    <li>{errors}</li>
                  </ul>

                  <div className="other-settings padding-left">
                    <div className="each-input">
                      <span className="input-heading">New Password</span>{" "}
                      <span className="red">*</span>
                      <div className="form-flex">
                        <FormInput
                          type="password"
                          name="newPassword"
                          value={editedPassword}
                          handleChange={(e) =>
                            setEditedPassword(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="inner-btns-flex">
                    <Button onClick={updatePassword}>Update</Button>

                    <Button onClick={closeEditUserPanel}>Close</Button>
                  </div>
                </div>
              </div>
            )}

            {deleteConfirmation && (
              <div className="popup-container">
                <div className="popup-card">
                  <p>Are you sure you want to delete this user?</p>
                  <div className="popup-buttons">
                    <button className='confirmDel' onClick={confirmDeleteUser}>Yes</button>
                    <button className='confirmDel' onClick={cancelDeleteUser}>No</button>
                  </div>
                </div>
              </div>
            )}

            <div className="user-manager-content">
              <div className="employee-list">
                <table
                  id="sales-tbl"
                  class="table table-centered table-borderless table-hover m-0"
                >
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>Username</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {employeeIds.length > 0 ? (
                    <tbody>
                      {sortedEmployeeIdsDescending.map((item, key) => {
                        // Convert the Unix timestamp to a Date object
                        const createdDate = new Date(item.createdDate);

                        // Format the date as a string (e.g., "yyyy-MM-dd HH:mm:ss")
                        const formattedDate = `${createdDate.getFullYear()}-${(
                          createdDate.getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}-${createdDate
                          .getDate()
                          .toString()
                          .padStart(2, "0")} ${createdDate
                          .getHours()
                          .toString()
                          .padStart(2, "0")}:${createdDate
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}:${createdDate
                          .getSeconds()
                          .toString()
                          .padStart(2, "0")}`;

                        return (
                          <tr key={key}>
                            <th>{key + 1}</th>
                            <th>{item.username}</th>
                            <th>{formattedDate}</th>
                            <th>
                              <ul className="action-btn">
                                <li
                                  className="edit-btn"
                                  onClick={() => openEditUserPanel(item)}
                                >
                                  Edit
                                </li>
                                <li
                                  className="delete-btn"
                                  onClick={() =>
                                    showDeleteConfirmation(item.id)
                                  }
                                >
                                  Delete
                                </li>
                              </ul>
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colspan="9" class="text-center">
                          <br />
                          <div className="center">
                            <img
                              src="https://app.vendloop.com/assets/images/empty.svg"
                              width="150"
                            />
                            <br />
                            <br />
                            <span class="font-bold">
                              No data available in table.
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                {/* <table>
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Employee ID</th>
                                    <th>Reference No</th>
                                    <th>Customer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employeeIds.map((id, key) => (
                                    <tr key={id}>
                                        <td>{key}</td>
                                        <td>{id}</td>
                                        <td>Reference No</td>
                                        <td>Customer</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
              </div>
            </div>

            <div
              className={
                AddNewUser
                  ? "addnewuser-container"
                  : "addnewuser-container active"
              }
            >
              <div className="overlay" onClick={closeAddUserPanel}></div>
              <div className="addnewuser-inner-container">
                <ul className="err">
                  <li>{errors}</li>
                </ul>

                <div className="other-settings padding-left">
                  <div className="each-input">
                    <span className="input-heading">User Name</span>
                    <span className="red">*</span>
                    <div className="form-flex">
                      <FormInput
                        type="text"
                        name="username"
                        value={UserName}
                        handleChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="each-input">
                    <span className="input-heading">Password</span>{" "}
                    <span className="red">*</span>
                    <div className="form-flex">
                      <FormInput
                        type="text"
                        name="nickname"
                        value={Password}
                        handleChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="each-input">
                    <span className="input-heading">Confirm Password</span>{" "}
                    <span className="red">*</span>
                    <div className="form-flex">
                      <FormInput
                        type="text"
                        name="nickname"
                        value={ConfirmPassword}
                        handleChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="inner-btns-flex">
                  <Button onClick={createuser}>Create Account</Button>

                  <Button onClick={closeAddUserPanel}>Close</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default UserManager