import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss'
import { firestore } from '../../../firebase/utils';
import * as Icon from 'react-feather';
import FormInput from '../../../Components/forms/FormInput';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
import PopUpClass from '../../../Components/PopUpClass';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { update } from 'firebase/database';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const Products = () => {
    const [popstat, setPopstat] = useState(false)
    const [poptitle, setPopTitle] = useState('')
    const [popmsg, setPopMsg] = useState('')
    const [expensesTab, setexpensesTab] = useState(false);
    const { currentUser, userErr } = useSelector(mapState);
    const [products, setProducts] = useState([]);
    const [productID, setproductId] = useState([])
    const [TotalAmt, setTotalAmt] = useState("0")

    const today = new Date();

    // Define an array of month names in abbreviated form
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get the month, day, and year
    const day = today.getDate();
    const month = monthNames[today.getMonth()]; // Get abbreviated month name
    const monthnumb = monthNames[today.getMonth()]; // Get abbreviated month name
    const year = today.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const fullyear = today.getFullYear().toString(); // Get the last two digits of the year


    const [preview, setPreview] = useState({});
    const [previewItems, setPreviewItems] = useState([]);
    const [previewState, setPreviewState] = useState(false);
    const [openPrdForm, setOpenPrdForm] = useState(false);

    const [pname, setPname] = useState(preview.pname);
    const [pprice, setPPrice] = useState(preview.pprice);
    const [qty, setqty] = useState(preview.qty);


    const fetchProductsData = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, "Users", currentUser.id, "Products"));
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());

                const emps = querySnapshot.docs.map((doc) => doc.data());
                const empsID = querySnapshot.docs.map((doc) => doc.id);

                const combinedData = emps.map((data, index) => ({
                    ...data, // Spread the data from emps
                    id: empsID[index], // Add the corresponding ID from empsID
                }));

                setProducts(combinedData);
                setFilteredData(combinedData)


            });

        } catch (error) {
            console.error("Error getting documents: ", error);
        }
    };

    useEffect(() => {
        fetchProductsData();
    }, []);

   const totalAmt = products.reduce((total, item) => total + item.qty * item.pprice, 0);

    // Format the totalSum as a money format
    const formattedTotalAmt = totalAmt.toLocaleString('en-US', {
        minimumFractionDigits: 2, // Specify the number of decimal places
    });

    const ExpensesTab = () => {
        setexpensesTab(true);
    }

    useEffect(() => {
        setexpensesTab(true);

        fetchPrd();
    }, [])

    const timestamp = preview?.uploadDate
        ? new Date(preview.uploadDate.seconds * 1000 + preview.uploadDate.nanoseconds / 1000000)
        : null;

    // Format the timestamp as "DD MMM YYYY, HH:MM:SS"
    const reviewoptions = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const formattedPreviewDateTime = timestamp
        ? timestamp.toLocaleString('en-NG', reviewoptions)
        : 'N/A';

    const openOrder = async (item) => {
        setPreview(item)
        setPPrice(item.pprice)
        setqty(item.qty)
        setPname(item.pname)
        checkPreviewState()
    }

    const checkPreviewState = () => {
        if (previewState) {
            setPreviewState(false)
        } else {
            setPreviewState(true)
        }
    }

    const updateItem = async (item) => {

        try {
            const querySnapshot = doc(firestore, "Users", currentUser.id, "Products", item.id);

            await updateDoc(querySnapshot,
                {
                    pprice: pprice,
                    qty: qty,
                    pname: pname,
                    updatedDate: today
                }).then(() => {
                    // console.log('updated')
                    checkPreviewState()
                }).then(() => {
                    fetchProductsData()
                }).catch(err => console.log(err));

            // console.log(querySnapshot)

        } catch (error) {
            console.error("Error updating documents: ", error);
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(products);

    const [addPrdQty, setaddPrdQty] = useState('');
    const [addPrdPrice, setaddPrdPrice] = useState('');
    const [addPrdPname, setaddPrdPname] = useState('');

    // Event handler for input change
    const handleInputChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);

        // Check if the search term is empty
        if (value.trim() === '') {
            // If it's empty, display all items
            setFilteredData(products);
        } else {
            // If not empty, filter the data based on the search term
            const filteredItems = products.filter((item) =>
                item.pname.toLowerCase().includes(value.toLowerCase())
            );

            // Update the state with the filtered data
            setFilteredData(filteredItems);
        }
    };

    const fetchPrd = () => {
        // console.log(formattedPrdID)
        const today = new Date();

        // Combine the month and year in the desired format
        // Extract day, month, year, hour, and minute components
        const prdday = today.getDate().toString().padStart(2, '0');
        const prdmonth = (today.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based
        const prdyear = today.getFullYear().toString().slice(-2); // Extract the last two digits of the year
        const prdhour = today.getHours().toString().padStart(2, '0');
        const prdminute = today.getMinutes().toString().padStart(2, '0');
        const prdsec = today.getSeconds().toString().padStart(2, '0');

        // Concatenate the components to create the desired format
        const formattedPrdID = `${prdday}${prdmonth}${prdyear}${prdhour}${prdminute}${prdsec}`;
        setproductId(formattedPrdID)
    }

    const openNewPrd = () => {
        fetchPrd();

        if (openPrdForm) {
            setOpenPrdForm(false)


        } else {
            if (currentUser.subscription === 'trial' && products.length < 10) {
                setOpenPrdForm(true)

            } else if (currentUser.subscription === 'Starter' && products.length < 50) {
                setOpenPrdForm(true)

            } else if (currentUser.subscription === 'PRO') {
                setOpenPrdForm(true)
            } else {
                setPopstat(true);
                setPopTitle('err:057');
                setPopMsg('You have reached the limit of products you can add. Kindly upgrade your account');

                setTimeout(() => {
                    setPopstat(false);

                }, 4000);
            }
        }
    }

    const addNewPrd = async () => {
        try {
            await setDoc(doc(firestore, "Users", currentUser.id, "Products", productID), {
                uploadDate: today,
                addedBy: "Admin",
                qty: addPrdQty,
                pprice: addPrdPrice,
                pname: addPrdPname
            }).then(() => {
                setaddPrdPname('')
                setaddPrdPrice('')
                setaddPrdQty('')
                openNewPrd();
                fetchProductsData()
            })
                .catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }
    }




    // const price = document.getElementById("price");
    // const quantity = document.getElementById("quantity");
    // const amount = price * quantity;
    // document.getElementById("amount").innerHTML = amount;


    return (
        <>
            <PopUpClass openBol={popstat} title={poptitle} message={popmsg} />

            <div className='products-container'>

                <div className='page-url-cont'>
                    <div className='page-url'>
                        {/* <h1>POS Sales</h1> */}
                        <Link to={'/app/overview'}><span className='colored'>Overview</span></Link>
                        <span className='slash'>/</span>
                        <span className='mainurl'>Products</span>
                    </div>
                </div>

                <div className='column1'>
                    <div className='each-box'>
                        <p className='box-head'>Total Products</p>
                        <p className='box-content'>{products.length}</p>
                    </div>
                    <div className='each-box'>
                        <p className='box-head'>Total Amount</p>
                        <p className='box-content'>₦{formattedTotalAmt}</p>
                    </div>
                </div>



                        

                {preview && previewState && (
                    <div className='preview-container'>
                        <div className='dark-overlay' onClick={checkPreviewState}></div>
                        <div className='preview-inner-container'>
                            <div className='head'>
                                <p>Edit product</p>

                            </div>
                            <div className='closeBtn'>
                                <Icon.XCircle color='#007558' stroke-width='3'
                                    onClick={checkPreviewState}
                                />
                            </div>
                            <ul>
                                <li>
                                    <div className='flex'>

                                        <div>
                                            <ul>
                                                <li>
                                                    <span>Product ID: {preview.id}</span>
                                                </li>
                                                <li>
                                                    <span>Date Added: {formattedPreviewDateTime}</span>
                                                </li>
                                                <li>
                                                    <span>Added By: {preview.addedBy}</span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='qrCode'>
                                            <QRCode value={preview.id} />
                                        </div>
                                    </div>
                                </li>
                                {/* <li>
                                <Barcode value={preview.id} />
                            </li> */}


                                <li>
                                    <div className='edit_form'>
                                        <div className="each-input">
                                            <span className='input-heading'>Products Name</span>
                                            <div className='form-flex'>

                                                <FormInput
                                                    type="text"
                                                    name="product_name"
                                                    value={pname}
                                                    handleChange={e => setPname(e.target.value)}
                                                />
                                            </div>

                                        </div>

                                        <div className="each-input">
                                            <span className='input-heading'>Price</span>
                                            <div className='form-flex' id='price'>

                                                <FormInput
                                                    type="text"
                                                    name="product_name"
                                                    value={pprice}
                                                    handleChange={e => setPPrice(e.target.value)}
                                                />
                                            </div>

                                        </div>

                                        <div className="each-input">
                                            <span className='input-heading'>Quantity</span>
                                            <div className='form-flex' id='quantity'>

                                                <FormInput
                                                    type="text"
                                                    name="product_name"
                                                    value={qty}
                                                    handleChange={e => setqty(e.target.value)}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <button onClick={() => updateItem(preview)}>Update</button>
                                </li>

                            </ul>
                        </div>

                    </div>
                )}

                {openPrdForm && (
                    <div className='preview-container'>
                        <div className='dark-overlay' onClick={() => setOpenPrdForm(false)}></div>
                        <div className='preview-inner-container'>
                            <div className='head'>
                                <p>Add new product</p>

                            </div>
                            <div className='closeBtn'>
                                <Icon.XCircle color='#007558' stroke-width='3'
                                    onClick={() => setOpenPrdForm(false)}
                                />
                            </div>

                            <ul>
                                <li>
                                    <div className='flex'>

                                        <div>
                                            <ul>
                                                <li>
                                                    Product ID: {productID}
                                                </li>

                                            </ul>
                                        </div>

                                        <div className='qrCode'>
                                            <QRCode value={productID} />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='edit_form'>
                                        <div className="each-input">
                                            <span className='input-heading'>Products Name</span>
                                            <div className='form-flex'>

                                                <FormInput
                                                    type="text"
                                                    name="product_name"
                                                    value={addPrdPname}
                                                    handleChange={e => setaddPrdPname(e.target.value)}
                                                />
                                            </div>

                                        </div>

                                        <div className="each-input">
                                            <span className='input-heading'>Price</span>
                                            <div className='form-flex'>

                                                <FormInput
                                                    type="text"
                                                    name="product_name"
                                                    value={addPrdPrice}
                                                    handleChange={e => setaddPrdPrice(e.target.value)}
                                                />
                                            </div>

                                        </div>

                                        <div className="each-input">
                                            <span className='input-heading'>Quantity</span>
                                            <div className='form-flex'>

                                                <FormInput
                                                    type="text"
                                                    name="product_name"
                                                    value={addPrdQty}
                                                    handleChange={e => setaddPrdQty(e.target.value)}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <button onClick={() => addNewPrd()}>Add</button>
                                </li>
                            </ul>

                        </div>
                    </div>
                )}

                <div className='pos-sales-content-container'>
                    <div className='tab-controls'>
                        <ul>
                            <li onClick={ExpensesTab}>
                                <span className={expensesTab ? 'tab1 active' : 'tab1'}>
                                    Products
                                </span>
                                <div className='add-icn' onClick={() => openNewPrd()}>
                                    <Icon.Plus color='#fff' stroke-width='3' size={40} />
                                </div>

                            </li>
                            <li>

                                <input
                                    type="text"
                                    placeholder="Search by name"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                />
                                <div className='ref-icn' onClick={fetchProductsData}>
                                    <Icon.RefreshCw color='#fff' stroke-width='3' />

                                </div>
                            </li>
                        </ul>

                    </div>
                    <div class="tab-content">
                        <div class={expensesTab ? "tab-pane" : "tab-pane active"} id="sales">
                            <div class="table-responsive">
                                <table id="sales-tbl" class="table table-centered table-borderless table-hover m-0">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>#</th>
                                            <th>Item ID</th>
                                            <th>Item</th>
                                            <th>Date</th>
                                            <th>Added By</th>
                                            <th class="text-center">Qty</th>
                                            <th class="text-right">Price</th>
                                            <th class="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    {products.length > 0 ? (
                                        <tbody>
                                            {filteredData.map((item, key) => {
                                                const timestamp = new Date(item.uploadDate.seconds * 1000 + item.uploadDate.nanoseconds / 1000000); // Convert to milliseconds

                                                // Format the timestamp as "DD MMM YYYY, HH:MM:SS"
                                                const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
                                                const formattedDateTime = timestamp.toLocaleString('en-NG', options);
                                                return (
                                                    <tr key={key}>
                                                        <th>{key + 1}</th>
                                                        <th>{item.id}</th>
                                                        <th>{item.pname}</th>
                                                        <th>{formattedDateTime}</th>
                                                        <th>{item.addedBy}</th>
                                                        <th>{item.qty}</th>
                                                        <th>{item.pprice}</th>
                                                        <th>
                                                            <ul className='action-btn'
                                                                onClick={() => openOrder(item)}
                                                            >
                                                                Edit
                                                            </ul>
                                                        </th>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colspan="9" class="text-center"><br />
                                                    <div className='center'>
                                                        <img src="https://app.vendloop.com/assets/images/empty.svg" width="150" />
                                                        <br /><br />
                                                        <span class="font-bold">No data available in table.</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Products