const productsTypes = {
  ADD_NEW_PRODUCT_START: 'ADD_NEW_PRODUCT_START',
  FETCH_PRODUCTS_START: 'FETCH_PRODUCTS_START',
  JUSTIN_FETCH_PRODUCTS_START: 'JUSTIN_FETCH_PRODUCTS_START',
  SET_PRODUCTS: 'SET_PRODUCTS',
  FETCH_PRODUCT_START_WITH_FILTER: 'FETCH_PRODUCT_START_WITH_FILTER',
  DELETE_PRODUCT_START: 'DELETE_PRODUCT_START',
  EDIT_PRODUCT_START: 'EDIT_PRODUCT_START',
  FETCH_PRODUCT_START: 'FETCH_PRODUCT_START',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_FILTER_PRODUCTS: 'SET_FILTER_PRODUCTS'
  
};

export default productsTypes;