import { auth } from './../../firebase/utils';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import {useHistory } from 'react-router-dom';
import { setProducts, setFilterProducts, editProductStart, setProduct, fetchProductsStart, fetchOnsaleStart, fetchProductStartWIthFilter } from './products.actions';
import {
  handleAddProduct, 
  handleFetchProducts,
  handleFetchOnsale,
  handlejustinFetchProducts,
  handleFetchProduct, 
  handleDeleteProduct,
  handleFetchProductsFilter,
  handleEditProduct,
  handleDeleteOnsale,
  handleAddOnsale
} from './products.helpers';
import productsTypes from './products.types';

export function* addProduct({ payload }) {

  try {
    const timestamp = new Date();
    yield handleAddProduct({
      ...payload,
      productAdminUserUID: auth.currentUser.uid,
      createdDate: timestamp
    });
    yield put(
      fetchProductsStart()
    );


  } catch (err) {
    // console.log(err);
  }

}

// export function* onAddOnsaleStart() {
//   yield takeLatest(productsTypes.ADD_NEW_ONSALE_START, addOnsale);
// }

// export function* addOnsale({ payload }) {

//   try {
//     const timestamp = new Date();
//     yield handleAddOnsale({
//       ...payload,
//       productAdminUserUID: auth.currentUser.uid,
//       createdDate: timestamp
//     });
//     yield put(
//       fetchOnsaleStart()
//     );


//   } catch (err) {
//     // console.log(err);
//   }

// }

export function* onAddProductStart() {
  yield takeLatest(productsTypes.ADD_NEW_PRODUCT_START, addProduct);
}

export function* fetchProducts({ payload }) {
  try {
    const products = yield handleFetchProducts(payload);
    yield put(
      setProducts(products)
    );

  } 
  catch (err) {
    // console.log(err);
  }
}

// export function* fetchOnsales({ payload }) {
//   try {
//     const products = yield handleFetchOnsale(payload);
//     yield put(
//       setProducts(products)
//     );

//   } 
//   catch (err) {
//     // console.log(err);
//   }
// }

export function* onFetchProductsStart() {
  yield takeLatest(productsTypes.FETCH_PRODUCTS_START, fetchProducts);
}

// export function* onFetchOnsaleStart() {
//   yield takeLatest(productsTypes.FETCH_ONSALE_START, fetchOnsales);
// }

export function* fetchProductsFilter({ payload }) {
  try {
    const products = yield handleFetchProductsFilter(payload);
    yield put(
      setFilterProducts(products)
    );

  } 
  catch (err) {
    // console.log(err);
  }
}

export function* onFetchProductsFilter() {
  yield takeLatest(productsTypes.FETCH_PRODUCT_START_WITH_FILTER, fetchProductsFilter);
}

export function* justinfetchProducts({ payload }) {
  try {
    const products = yield handlejustinFetchProducts(payload);
    yield put(
      setProducts(products)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onjustinFetchProductsStart() {
  yield takeLatest(productsTypes.JUSTIN_FETCH_PRODUCTS_START, justinfetchProducts);
}

export function* deleteProduct({ payload }) {
  try {
    yield handleDeleteProduct(payload);
    // yield put(
    //   fetchProductsStart()
    // );

  } catch (err) {
    // console.log(err);
  }
}

export function* deleteOnsale({ payload }) {
  try {
    yield handleDeleteOnsale(payload);
    // yield put(
    //   fetchProductsStart()
    // );

  } catch (err) {
    // console.log(err);
  }
}

export function* onDeleteProductStart() {
  yield takeLatest(productsTypes.DELETE_PRODUCT_START, deleteProduct);
}

// export function* onDeleteOnsaleStart() {
//   yield takeLatest(productsTypes.DELETE_ONSALE_START, deleteOnsale);
// }

export function* editProduct({ payload }) {
  try {
    yield handleEditProduct(payload);
    // yield put(
    //   fetchProductsStart()
    // );

  } catch (err) {
    // console.log(err);
  }
}

export function* onEditProductStart() {
  yield takeLatest(productsTypes.EDIT_PRODUCT_START, editProduct);
}

export function* fetchProduct({ payload }) {
  try {
    const product = yield handleFetchProduct(payload);
    yield put(
      setProduct(product)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchProductStart() {
  yield takeLatest(productsTypes.FETCH_PRODUCT_START, fetchProduct);
}

export default function* productsSagas() {
  yield all([
    call(onAddProductStart),
    call(onFetchProductsStart),
    call(onDeleteProductStart),
    // call(onFetchOnsaleStart),
    // call(onDeleteOnsaleStart),
    call(onjustinFetchProductsStart),
    call(onEditProductStart),
    call(onFetchProductsFilter),
    call(onFetchProductStart),
  ])
}