import React from 'react'
import Signup from '../../Components/Signup'
import Footer from '../../Components/Footer';


function Registration() {
  return (
    <>
      <Signup />
      {/* <Footer /> */}
    </>
  )
}

export default Registration