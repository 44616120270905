import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { checkUserSession } from "./redux/User/user.actions";
import { useDispatch } from "react-redux";
import "./index.scss";
import { ChatWidget } from "@papercups-io/chat-widget";

// Pages
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import POS from "./pages/POS";
import Pricing from "./pages/Pricing";
import RecoverPassword from "./pages/RecoverPassword";
import AdminManagement from "./pages/Admin/AdminManagement";


//components
import GetStarted from './Components/GetStarted';
import Download from './Components/Download';
import Dialogue from './Components/Dialogue';

//Layouts
import AdminLayout from "./layouts/AdminLayout";
import HomepageLayout from "./layouts/HomepageLayout";
import NoNavLayout from "./layouts/NoNavLayout";
import SuperUserLayout from "./layouts/SuperUserLayout";

//hoc
import WithAuth from './hoc/withAuth';
// import WithAdminAuth from './hoc/withAdminAuth';

//Add Ons
import ScrollButton from "./Components/ScrollButton";

//User Amdin
import POSSales from './AdminManagement/Components/POSSales';
import Expenses from './AdminManagement/Components/Expenses';
import Products from './AdminManagement/Components/Products';
import CompanyProfile from './AdminManagement/Components/Company Profile';
import UserManager from './AdminManagement/Components/UserManager';
import Subscription from './AdminManagement/Components/Subscription';
// import InAppMessage from './Components/InAppMessage';

//SuperUser
import AdminLogin from './SuperUserDashboard/Login';
import DashBoard from "./SuperUserDashboard/Dashboard/Dashboard";


const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const [popstat, setPopstat] = useState(false)
  // const [poptitle, setPopTitle] = useState('')
  // const [popmsg, setPopMsg] = useState('')

  useEffect(() => {
    dispatch(checkUserSession());
    window.scrollTo(0, 0);
    // checkAppMsg()

  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    // checkAppMsg();
  }, []);

  return (
    <React.Fragment className="App">
      {loading ? (
        // <div className="loading-overlayy">
        // <div className='topprogressbarr'></div>
        // <div class="lds-hourglasss"></div>
        // </div>
        <Dialogue />
      ) : (
        <Router>
          <Switch>


            {/* landing page links */}
            <Route
              exact
              path="/"
              render={() => (
                <HomepageLayout>
                  <Homepage />
                </HomepageLayout>
              )}
            />

            <Route
              path="/pos"
              render={() => (
                <HomepageLayout>
                  <POS />
                </HomepageLayout>
              )}
            />

            <Route path="/pos" render={() => (
              <HomepageLayout>
                <POS />
              </HomepageLayout>
            )} />

            <Route
              path="/pricing"
              render={() => (
                <HomepageLayout>
                  <Pricing />
                </HomepageLayout>
              )}
            />

            <Route
              path="/download"
              render={() => (
                <HomepageLayout>
                  <Download />
                </HomepageLayout>
              )}
            />

            <Route
              path="/get-started"
              render={() => (
                <NoNavLayout>
                  <GetStarted />
                </NoNavLayout>
              )}
            />

            <Route
              path="/registration"
              render={() => (
                <NoNavLayout>
                  <Registration />
                </NoNavLayout>
              )}
            />

            <Route
              path="/login"
              render={() => (
                <NoNavLayout>
                  <Login />
                </NoNavLayout>
              )}
            />

            <Route
              path="/recoverPassword"
              render={() => (
                <NoNavLayout>
                  <RecoverPassword />
                </NoNavLayout>
              )}
            />



            {/* USER ADMIN PAGE LINKS */}
            <Route
              path="/app/overview"
              render={() => (
                <WithAuth>
                  <AdminLayout>
                    <AdminManagement />
                  </AdminLayout>
                </WithAuth>
              )}
            />

            <Route
              path="/app/pos-sales"
              render={() => (
                <WithAuth>
                  <AdminLayout>
                    <POSSales />
                  </AdminLayout>
                </WithAuth>
              )}
            />



            <Route
              path="/app/expenses"
              render={() => (
                <WithAuth>
                  <AdminLayout>
                    <Expenses />
                  </AdminLayout>
                </WithAuth>
              )}
            />

            <Route
              path="/app/products"
              render={() => (
                <WithAuth>
                  <AdminLayout>
                    <Products />
                  </AdminLayout>
                </WithAuth>
              )}
            />
            <Route
              path="/app/usersmanager"
              render={() => (
                <WithAuth>
                  <AdminLayout>
                    <UserManager />
                  </AdminLayout>
                </WithAuth>
              )}
            />

            <Route
              path="/app/subscription"
              render={() => (
                <WithAuth>
                  <AdminLayout>
                    <Subscription />
                  </AdminLayout>
                </WithAuth>
              )}
            />

            <Route
              path="/app/companyprofile"
              render={() => (
                <WithAuth>
                  <AdminLayout>
                    <CompanyProfile />
                  </AdminLayout>
                </WithAuth>
              )}
            />

            <Route
              path="/app/usersmanager"
              render={() => (
                <WithAuth>
                  <AdminLayout>
                    <UserManager />
                  </AdminLayout>
                </WithAuth>
              )}
            />

            {/* <Route path="/store/" render={() => (
            <WithAuth>
              <AdminLayout>
                <Store />
              </AdminLayout>
            </WithAuth>
          )} />
           */}


            {/* Supper Users Routes */}
            {/* Please use the flow (/admin/superuser/98765456789/) for every
            path in this route */}
            <Route
              path="/admin/superuser/98765456789/login"
              render={() => (
                <NoNavLayout>
                  <AdminLogin />
                </NoNavLayout>
              )}
            />

            <Route
              path="/admin/superuser/98765456789/dashboard"
              render={() => (
                // <WithAuth>
                <SuperUserLayout>
                <DashBoard />
                </SuperUserLayout>
                // </WithAuth>
              )}
            />
          </Switch>
        </Router>
      )}

      <ScrollButton />

      {/* <InAppMessage openBol={popstat} title={poptitle} message={popmsg} /> */}

      <ChatWidget
        token="734be42c-94cc-46c6-8bc2-71208c3a15c9"
        inbox="bd611a81-bdf1-4bf6-80ac-16117225af43"
        title="Welcome to Mpos"
        subtitle="Ask us anything in the chat window below 😊"
        primaryColor="#004937"
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={true}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={true}
        iconVariant="filled"
        baseUrl="https://app.papercups.io"
      />
    </React.Fragment>
  );
};

export default App;
