import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import check from "../../assets/Check.svg";
import cancel from "../../assets/cancel2.svg";

import "./styles.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Index = () => {
  const [price, setPrice] = useState(false);

  const handleClick = () => setPrice(!price);

  return (
    <section className="pricing">
      <div className="pricing_block">
        <h1 className="major">Priced built for all business needs </h1>
        <p className="pricing_text">
          Pick one today to turn your business around.
        </p>
      </div>

      <div className="pricing_price">
        <div className="options">
          <span className={price ? "yearly" : "monthly"} onClick={handleClick}>
            Pay monthly
          </span>
          <span className={price ? "monthly" : "yearly"} onClick={handleClick}>
            Pay yearly (save 5%)
          </span>
        </div>

        <div className={price ? "price_options none" : "price_options"}>
          <div className="basic">
            <h2>Basic</h2>
            <p className="basic_text">
              For individuals and teams <br /> getting started
            </p>
            <p className="main_price">
              #<span>0</span> Free
            </p>
            <p className="benefits_start">Includes:</p>
            <div>
              {/* <div className="benefits">
                <img src={check} alt="" />
                <p>Create an online store</p>
              </div> */}
              <div className="benefits">
                <img src={check} alt="" />
                <p>Manage 1 offline store</p>
              </div>
              <div className="benefits not">
                <img src={cancel} alt="" />
                <p>Access admin tools</p>
              </div>
              <div className="benefits not">
                <img src={cancel} alt="" />
                <p>1 user</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Upload 10 products</p>
              </div>
            </div>
            <Link to="/get-started">
              <button>Get started</button>
            </Link>
          </div>

          <div className="basic">
            <h2>Starter</h2>
            <p className="basic_text">For small businesses </p>
            <p className="main_price">
              #<span>3000</span>
            </p>
            <p className="benefits_start">Includes:</p>
            <div>
              {/* <div className="benefits">
                <img src={check} alt="" />
                <p>Create an online store</p>
              </div> */}
              <div className="benefits">
                <img src={check} alt="" />
                <p>Manage 1 offline stores</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Access admin tools</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>3 users</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Upload 50 products</p>
              </div>
            </div>
            <Link to="/get-started">
              <button>Get started</button>
            </Link>
          </div>

          <div className="basic">
            <h2>Pro</h2>
            <p className="basic_text">For medium to large businesses </p>
            <p className="main_price">
              #<span>5000</span>
            </p>
            <p className="benefits_start">Includes:</p>
            <div>
              {/* <div className="benefits">
                <img src={check} alt="" />
                <p>Create an online store</p>
              </div> */}
              <div className="benefits">
                <img src={check} alt="" />
                <p>Manage 1 offline store</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Access admin tools</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Unlimited user</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Upload unlimited products</p>
              </div>
            </div>
            <Link to="/get-started">
              <button>Get started</button>
            </Link>
          </div>
        </div>

        <div className={price ? "price_options" : "price_options none"}>
          <div className="basic">
            <h2>Basic</h2>
            <p className="basic_text">
              For individuals and teams <br /> getting started
            </p>
            <p className="main_price">
              #<span>0</span> Free
            </p>
            <p className="benefits_start">Includes:</p>
            <div>
              {/* <div className="benefits">
                <img src={check} alt="" />
                <p>Create an online store</p>
              </div> */}
              <div className="benefits">
                <img src={check} alt="" />
                <p>Manage 1 offline store</p>
              </div>
              <div className="benefits not">
                <img src={cancel} alt="" />
                <p>Access admin tools</p>
              </div>
              <div className="benefits not">
                <img src={cancel} alt="" />
                <p>1 user</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Upload 10 products</p>
              </div>
            </div>
            <Link to="/get-started">
              <button>Get started</button>
            </Link>
          </div>

          <div className="basic">
            <h2>Starter</h2>
            <p className="basic_text">For small businesses </p>
            <p className="main_price">
              #<span>30,000</span>
            </p>
            <p className="benefits_start">Includes:</p>
            <div>
              {/* <div className="benefits">
                <img src={check} alt="" />
                <p>Create an online store</p>
              </div> */}
              <div className="benefits">
                <img src={check} alt="" />
                <p>Manage 1 offline stores</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Access admin tools</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>3 users</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Upload 50 products</p>
              </div>
            </div>
            <Link to="/get-started">
              <button>Get started</button>
            </Link>
          </div>

          <div className="basic">
            <h2>Pro</h2>
            <p className="basic_text">For medium to large businesses </p>
            <p className="main_price">
              #<span>50,000</span>
            </p>
            <p className="benefits_start">Includes:</p>
            <div>
              {/* <div className="benefits">
                <img src={check} alt="" />
                <p>Create an online store</p>
              </div> */}
              <div className="benefits">
                <img src={check} alt="" />
                <p>Manage 1 offline store</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Access admin tools</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Unlimited user</p>
              </div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>Upload unlimited products</p>
              </div>
            </div>
            <Link to="/get-started">
              <button>Get started</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="hardware">
        <h2>Hardware Plans</h2>

        <div className="hardware_plans">
          <div className="basic">
            <h2>Starter</h2>
            <p className="basic_text">For small businesses </p>
            <p className="main_price">
              #<span>5000</span>
            </p>
            <p className="benefits_start">Includes:</p>
            <div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>1 Mobile Printer</p>
              </div>
              <div className="benefits not">
                <img src={cancel} alt="" />
                <p>1 Barcode Scanner</p>
              </div>
            </div>
            <Link to="/get-started">
              <button>Get started</button>
            </Link>
          </div>

          <div className="basic">
            <h2>Pro</h2>
            <p className="basic_text">
              Level up your business with <br /> professional tools.
            </p>
            <p className="main_price">
              #<span>8000</span>
            </p>
            <p className="benefits_start">Includes:</p>
            <div>
              <div className="benefits">
                <img src={check} alt="" />
                <p>1 Mobile Printer</p>
              </div>
              <div className="benefits not">
                <img src={cancel} alt="" />
                <p>1 Barcode Scanner</p>
              </div>
            </div>
            <Link to="/get-started">
              <button>Get started</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="cta">
        <h2>Choose a plan today to turn your business around.</h2>
        <p>Sign up now and try MPOS features for free.</p>
        <Link to="/get-started">
          <button className="free">Try for free</button>
        </Link>
      </div>

    </section>
  );
};

export default Index;
