import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Navbar';



const HomepageLayout = props => {
  return (
    <div className="fullHeight">
      <Header {...props} />
      <div>
        {props.children}
      </div>
      <Footer {...props} />
    </div>
  );
};

export default HomepageLayout;
