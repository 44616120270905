import React, { useState } from 'react'
import "./styles.scss"
import { auth } from "../../firebase/utils"
import { Link } from 'react-router-dom'
import Logo from '../../assets/mposlogo.png'
import Button from "../../Components/forms/Button"
import FormInput from '../../Components/forms/FormInput'

function ForgotPassword() {


  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(true);



  const resetPassword = async (e) => {
    handleClick()
    e.preventDefault();
    console.log(email);
    setError("");

    auth.sendPasswordResetEmail(email).then(() => {
      console.log("Password reset email sent");
      e.target.reset()
      setClick(false);
      setTimeout(() => {
        setMessage("Sent! Check your email or spam folder to reset password")
      })
    }
    ).catch(error => {
      console.log(error);
      setError("");
      e.target.reset()
      setClick(false);
      setTimeout(() => {
        setError("Email not found");
      })

    });

  }

  return (

    <div className='recoverBackground'>


      <div className='mainRecover'>

        <div className={click ? 'loading-overlay' : 'loading-overlay active'}>
          {/* <div class="lds-dual-ring"></div> */}
          <div className='topprogressbar'></div>

          <div class="lds-hourglass"></div>
        </div>

        <div className='RecoverLogo'>
          <a href='/'>
            <img src={Logo} alt='logo' />
          </a>
        </div>

        <div className='RecoverHead'><p>Recover Password</p></div>
        <div><p className='recoverTxt'>Enter your Email and instructions will be sent to you!</p></div>


        <form onSubmit={resetPassword}>

          {message && (<p className='message'>{message}</p>)}

          <ul className="err">
            <li>
              {error}
            </li>
          </ul>

          <div className='input-header'>Email Address*</div>
          <div className=' input-field'>
            <input
              type="text"
              name="email"
              value={email}
              placeholder="Enter your email"
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>

          <div className='span'>
            <Button type="submit">
              <span >
                Login
              </span>
            </Button>
          </div>

          <div className='dha'>
            <p>Go back to<Link to="/"> Login Page</Link></p>
          </div>



        </form>

      </div>

      <div className='emptyRecover'>

      </div>
    </div>
  )
}

export default ForgotPassword