import React, { useState } from 'react';
import Logo from '../../assets/mposlogo.png'
import './styles.scss';


function SuperUserDashboard() {
    const [isDarkMode, setIsDarkMode] = useState(false);
  
    const toggleBtn = () => {
      setIsDarkMode(!isDarkMode);
    };
  
    const handleLogout = () => {
      alert('Logout successful');
    };
  
    return (
      <div className={`header-container ${isDarkMode ? 'dark-mode' : ''}`}>
        <header>
          <div className="logo">
            <img src={Logo} alt="MPOS" className="logo" />
          </div>
          <nav>
            <ul>
              <li className="nav-link dashboard-link">
                <a href="#dashboard">Dashboard</a>
              </li>
              <li className="nav-link users-link">
                <a href="#users">Users</a>
              </li>
              <li className="nav-link transactions-link">
                <a href="#transactions">Transactions</a>
              </li>
            </ul>
            <button
              type="button"
              className={`tog-btn ${isDarkMode ? 'active' : ''}`}
              onClick={toggleBtn}
            >
              <span></span>
            </button>
            <button type="button" className="btn" onClick={handleLogout}>
              Logout
            </button>
          </nav>
        </header>
      </div>
    );
  }
  
  export default SuperUserDashboard;
