const ordersTypes = {
  SAVE_ORDER_HISTORY_START: 'SAVE_ORDER_HISTORY_START',

  GET_USER_ORDER_HISTORY_START: 'GET_USER_ORDER_HISTORY_START',
  SET_USER_ORDER_HISOTRY: 'SET_USER_ORDER_HISOTRY',

  GET_ADMIN_ORDER_HISTORY_START: 'GET_ADMIN_ORDER_HISTORY_START',
  SET_ADMIN_ORDER_HISOTRY: 'SET_ADMIN_ORDER_HISOTRY',

  GET_ADMIN_ORDER_TRACK_START: 'GET_ADMIN_ORDER_TRACK_START',
  SET_ADMIN_ORDER_TRACK: 'SET_ADMIN_ORDER_TRACK',

  GET_ORDER_DETAILS_START: 'GET_ORDER_DETAILS_START',
  SET_ORDER_DETAILS: 'SET_ORDER_DETAILS',

  GET_ADMIN_ORDER_DETAILS_START: 'GET_ADMIN_ORDER_DETAILS_START',
  SET_ADMIN_ORDER_DETAILS: 'SET_ADMIN_ORDER_DETAILS',

  GET_ADMIN_ORDER_TRACK_DETAILS_START: 'GET_ADMIN_ORDER_TRACK_DETAILS_START',
  SET_ADMIN_ORDER_TRACK_DETAILS: 'SET_ADMIN_ORDER_TRACK_DETAILS'
};

export default ordersTypes;